
import React, {useEffect, useState} from 'react';
import {
    Container, Row, Col, 
    // Cell, CellIcon,
    Header,
    // Card,
    // CardBody,
    // CardContent,
    // CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    // TextBoxBigTitle,
    // TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    TextField,
    Button,
    Tabs, TabItem,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    ActionButton,
    Spinner,
// Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import  {
    WarningOutlined,
    AudioOutlined,
    PushpinFilled
 } from '@ant-design/icons';

 import {
    IconApps,
    IconCalendar,
    IconEye,
    IconSleep,
    IconPersone,
    IconLock,
    IconPower,
    IconMicNone,
    IconMic
 } from '@sberdevices/plasma-icons';

import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
    useHistory
  } from "react-router-dom";
  

// import MeetCards from './cards.js'
// import Calendar from './calendar.js'

// import { Progress } from 'antd';

import { Row as Row2, Col as Col2, notification } from 'antd';

import api from './../api/api'
import RecorderList from '../MeetingsPage/rec/RecorderList';


function LoginForm(props) {

    const [valLogin, setLogin] = useState('test@test.com');
    const [valPass, setPass] = useState('11111111111111111111111111111111');
    const [is_loading, setLoading] = useState(false);
    const [is_error, setError] = useState(false);
    // let history = useHistory();
    
    return (<>
        <TextField
                value={valLogin}
                label={'Логин'}
                // helperText={'Helper text'}
                // helperText={valLogin}
                disabled={false}
                // status={'success'}
                contentLeft={<IconPersone color="inherit" size="s" />}
                // contentRight={
                //     <ActionButton view="clear">
                //         <IconEye color="inherit" size="s" />
                //     </ActionButton>
                // }
                onChange={(v) => { setLogin(v.target.value); setError(false) }}
                // onFocus={action('onFocus')}
                // onBlur={action('onBlur')}
            />
        <br/>
        <TextField
                value={valPass}
                label={'Пароль'}
                type='password'
                helperText={ is_error ? 'Проверьте логин и пароль' : ''}
                // helperText={valLogin}
                disabled={false}
                // status={'success'}
                status={'error'}
                contentLeft={<IconLock color="inherit" size="s" />}
                // contentRight={
                //     <ActionButton view="clear">
                //         <IconEye color="inherit" size="s" />
                //     </ActionButton>
                // }
                onChange={(v) => { setPass(v.target.value); setError(false) }}
                // onFocus={action('onFocus')}
                // onBlur={action('onBlur')}
            />
        <br/>
        <Button view="primary" stretch={true} onClick={() => { 
            setError(false)
            setLoading(true)
            api.auth(valLogin, valPass).then(
                (res) => { 
                    setLoading(false)
                    props.onLogin(); 
                }, 
                (err) => { 
                    console.log(err, err.request)

                    if (err.request && err.request.status == 401 ) { // не авторизован
                        // window.open('/login')
                        //document.location.href = ('/login')
                        // this.redirect('/login')
                    }
                    notification.open({
                        duration: 0,
                        message: err.message,
                        description: err.config?.url,
                        onClick: () => {
                            // window.open('https://45.89.225.221:9100/get_meetings', '_blank').focus();
                            window.open(api.get_test_url(), '_blank').focus();
                        },
                        icon: <WarningOutlined style={{ color: 'red' }} />
                    });

                    setLoading(false)
                    setError(true) 
                }
            )
            
        }}>{ is_loading ? <Spinner size={20} color={'white'}/> : 'Войти' }</Button>
        {/* {error ? 1 : 0} */}
    </>)
}



export class PlayStopBigBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlay: false,
            loading: false, 
            // isCommand: false,
            v: 0,
            // showAlarm: false
        }
    }
    componentDidMount() {
        this.props.reclist.addVolumeListener (this.onVolume)
    }
    componentWillUnmount() {
        this.onStop()
        this.props.reclist.removeVolumeListener (this.onVolume)
    }

    onVolume = (e) => {
        this.setState({ v: e.detail.v }) 
    
        // if (e.detail.v > 85 && this.state.showAlarm == false) {
        //     this.setState({ showAlarm: true }) 
        //     notification.open({
        //         // duration: 0,
        //         message: 'Пожалуйста, потише!',
        //         placement: 'bottomRight',
        //     //     // description: '123',
        //         icon: <AudioOutlined style={{color:'red'}} />, 
        //     });
        //     // message.error( 'Пожалуйста, потише!' );
        // }
        // if (e.detail.v < 10 && this.state.showAlarm == true) {
        //     this.setState({ showAlarm: false })
        // }
    }

    onPlay = () => {
        this.setState({ loading: true })
        this.props.reclist.startRecording(0, 20000, 5000, true).then(() => {
        // this.props.reclist.startRecording(this.props.meet_id, 2000).then(() => {
        // this.props.reclist.startRecording(this.props.meet_id, 10000).then(() => {
        // this.props.reclist.startRecording(10000).then(() => {
            this.setState({ loading: false })
            this.setState({ isPlay: true })
        })
    }
    onStop = () => {
        // this.onEndCommand();
        this.setState({ loading: true })
        this.props.reclist.stopRecording().then(res => {
            this.setState({ loading: false })
            this.setState({ isPlay: false });
        })
    }
    // onBeginCommand = () => {
    //     this.setState({ isCommand: true });
    //     this.props.reclist.beginCommand()
    // }
    // onEndCommand = () => {
    //     this.setState({ isCommand: false });
    //     this.props.reclist.endCommand()
    // }
    render() {
        return !this.state.isPlay ? 
            (<Button view="secondary" pin="circle-circle" onClick={this.onPlay} square={true} className="bigbtn" size="l" >
                {/* <IconMicNone /> Start */}
                {/* <IconPower />&nbsp;Старт */}
                    {/* <IconMicNone/> */}
                    {this.state.loading ? <Spinner color="white"/> : <IconMicNone className="bigicon"/> }
            </Button>) :
            (<nobr>
                {/* <Button view="primary" pin="circle-circle" onClick={this.onStop} style={{ */}
                <Button view="primary" pin="circle-circle" onClick={this.onStop} style={{
                    // background: 'linear-gradient( 3deg, #9a181800, #9a181800, #b00e0e)',
                    backgroundImage: 'linear-gradient( 0deg, #b00e0e, yellow, #2AC673 50%, #9a181800 50%, #9a181800)',
                    // backgroundImage: 'url(/320_320_0.jpg)',
                    backgroundSize: '240px 440px',
                    // backgroundSize: '200px 80px',
                    backgroundRepeat: 'no-repeat',
                    // backgroundSize: '200%',
                    backgroundPositionY: (-this.state.v/100*150) + 'px'
                    // backgroundPositionY: (-this.state.v/100*40) + 'px'
                }} square={true} className="bigbtn" size="l" >
                    {/* <IconMic /> Stop */}
                    {/* <IconPower />&nbsp;Стоп */}

                    {this.state.loading ? <Spinner color="white"/> : <IconMic className="bigicon"/> }
                </Button>
                {/* &nbsp;
                &nbsp;
                {this.renderBtnCommand()} */}
            </nobr>)
    } 
    // renderBtnCommand() {
    //     return !this.state.isCommand ? 
    //         (<Button view="secondary" size="l" pin="circle-circle" style={{fontSize:'24px'}}
    //             onClick={this.onBeginCommand} contentLeft={<PushpinFilled />} />) :
    //         (<Button view="primary" style={{background: '#108ee9', fontSize:'24px'}} size="l" pin="circle-circle" 
    //             onClick={this.onEndCommand} contentLeft={<PushpinFilled />} />)
    // }

    // <ActionButton size={'l'} view={'primary'}>
    //     <IconMic />
    // </ActionButton>
}



// var loacl_reclist = new RecorderList(0, [])

export default function CommandPage(props) {

    const [ is_logined, set_is_logined ] = useState( false );
    const [ rec, setRec ] = useState( false );
    const [ R, setR ] = useState( {} );
    const history = useHistory();

    const sayHello = () => {
        set_is_logined(true)
        setTimeout(() => {
            props.onLogin()
        }, 2500)
    }

    useEffect(()=> {
        setR(new RecorderList(0, []))
    }, [])

    // const onBigBtn = (e) => {
    //     console.log(R)
    //     if (!rec) {
    //         R.startRecording(0, 20000, 5000, true).then(() => {
    //             // this.props.reclist.startRecording(this.props.meet_id, 10000).then(() => {
    //                 // this.props.reclist.startRecording(10000).then(() => {
    //                     // this.setState({ isPlay: true })
    //                     setRec(true)
    //                 })
    //     } else {
    //         R.stopRecording()
    //         setRec(false)
    //     }
    // }

    return (
        <Container className={'main_center_wrapper ' + (!is_logined ? '' : 'is_logined')}>
            
                <div></div>


                <div>
                    <img className="dmm-logo" src={"/logo_sdm.png"} onClick={()=>history.push('/')} />
                </div>



                <div className="login-form-wrapper" style={{textAlign:'center'}}>
                    { R.addVolumeListener ? <PlayStopBigBtn reclist={R}></PlayStopBigBtn> : '' }
                    {/* <Button className="bigbtn" size="l" onClick={()=>onBigBtn()}
                        view={rec ? 'primary': 'secondary'} pin="circle-circle" square={true} >
                            <IconMicNone/>
                    </Button> */}
                    <br/>
                    <br/>
                    <h2 style={{textAlign:'center'}}>Запись поручений</h2>
                    {/* <Button className="bigbtn" size="l" view="primary" pin="circle-circle" square={true} ><IconMicNone/></Button> */}
                    {/* <ActionButton size="M" pin="circle-circle" ></ActionButton> */}
                    {/* <Button size="l" view="primary" pin="circle-circle" square={true} ><IconMicNone/></Button> */}
                    {/* <LoginForm onLogin={ () => props.onLogin() } /> */}
                    {/* { !is_logined ? 
                        <LoginForm onLogin={ () => sayHello() } />
                        :
                        <div className="welcomeText" style={{textAlign:'center'}}>
                            <h1>Привет, {api.profile().username}!</h1>
                            <br />
                            
                            <big>
                                Добро пожаловать <br/>в мир DMM
                            </big>
                        </div>
                    } */}
                </div>

                <div></div>
                <div></div>

                <div className="footer-sberai">
                    <p>
                        <small>powered by</small><img src="/sberai_logo.svg" />
                    </p>
                </div>

                <style>
                    {`
                        .bigbtn {
                            width: 15rem;
                            height: 15rem;
                            border-radius: 15rem;
                        }
                        .bigbtn:before {
                            top: -0.2rem;
                            left: -0.2rem;
                            right: -0.2rem;
                            bottom: -0.2rem;
                            border-sise: 0.2rem;
                            border-radius: 15rem;
                        }
                        .bigbtn .bigicon {
                            flex: 0 0 7rem;
                            height: 7rem;
                        }
                        .main_center_wrapper{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100vh;
                            align-items: center;
                        }
                        .dmm-logo {
                            width:170px;
                            margin: 30px auto 30px;
                            display: block;
                        }
                        .login-form-wrapper {
                            max-width: 400px;
                            width: 100%;
                            margin: 0 auto;
                            transition: all 1s ease-out;
                        }
                        .login-form-wrapper {
                            height:220px;
                        }
                        .is_logined .login-form-wrapper {
                            height:150px;
                        }
                        .welcomeText {
                            opacity: 0;
                            transition: all 1s ease-out;
                        }
                        .is_logined .welcomeText {
                            opacity: 1;
                        }
                        .footer-sberai img {
                            vertical-align: middle;
                            width: 90px;
                            margin: 10px;
                        }
                    `}
                </style>

        </Container>
    )
}