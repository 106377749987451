
export default [
    {
        name:"Искажение коллективной ответственности",
        title:"Искажение коллективной ответственности",
        short: `Задача была адресована сразу нескольким участникам - это может привести к «искажению коллективной ответственности» (подробнее).

        Что делать:
        В подобных случаях рекомендуется либо назначить одного ответственного, либо разбить задачу на части и адресно определить, кто за какую часть отвечает.`,
        long: `В ходе совещания задача была адресована сразу нескольким участникам. Такие задачи рискуют быть не выполненными, поскольку каждый из участников может надеяться на остальных. Эта особенность людей называется “искажением коллективной ответственности”.

        `,
        // Пример: Эту особенность доказал эксперимент исследователей из Колумбийского университета. При просмотре контента участники группы должны были кричать и аплодировать «изо всех сил», сначала в одиночку, потом вместе с другими участниками. Предполагалось, что участники  вместе с другими будут кричать громче, поскольку будут чувствовать себя раскованно. В реальности же вместе с другими испытуемые производили в три раза меньше шума, нежели по отдельности. При этом сами участники эксперимента считали, что в обоих случаях «выкладывались» одинаково.
    },
    {
        name:"Отклонение в сторону статуса кво",
        title:"Отклонение в сторону статуса&nbsp;кво",
        short: `Прозвучали фразы, отвергающие изменения. Это может свидетельствовать о когнитивном искажении «Отклонение в сторону статуса кво» (подробнее)

        Что делать:
        В ситуации, когда вам предлагают что-то новое, прежде чем отказаться, попробуйте придумать хотя бы три причины, по которым предложение может быть выигрышным. Это заставит мозг принять в расчет больше информации.`,
        long: `Люди привыкают думать и действовать определенным образом. Поэтому, когда меняется привычный ход вещей, они тревожатся, часто делают выбор в пользу привычного, стремятся сохранить статус-кво. В итоге, отвергая изменения, мы упускаем многие возможности.

        Пример: сооснователь BlackBerry Майк Лазаридис отказался производить телефоны с сенсорным экраном, потому что «этого он не понимает, а многокнопочные модели и так хорошо продаются корпоративным клиентам». Мы уже знаем, что выход первого же iPhone показал, как Лазаридис ошибался.`,
    },
    {
        name:"Ошибка планирования",
        title:"Ошибка планирования",
        short: `В ходе совещания были озвучены сроки исполнения. Есть опасность возникновения когнитивного искажения «Ошибка планирования» (подробнее)

        Что делать:
        1) разделите задачу на составные элементы максимально подробно
        2) запланируйте срок выполнения каждого этапа задачи
        3) суммируйте необходимое время
        
        Чем качественнее разбита задача, тем точнее можно запланировать время работы над ней.`,
        long: `Большинство проектов не заканчиваются в срок. Это связано с когнитивным искажением «Ошибка планирования"": мы слишком оптимистично прогнозируем временные затраты на задачу. Исследователи выявили любопытный эффект: время, которое мы отводим на выполнение задачи в целом, меньше, чем суммарное время, отводимое на выполнение каждой из частей этой задачи. 

        Пример: В ходе исследований у 37 студентов спросили, сколько времени им потребуется, чтобы завершить работу над дипломной работой. Средняя оценка требуемого времени составила 33,9 дня. По факту на работу ушло в среднем 55,5 дня. Правильную оценку смогли дать только 30% студентов.`,
    },
    {
        name:"Избегание неопределенности",
        title:"Избегание неопределенности",
        short: `Прозвучало несколько вопросов. Это может свидетельствовать о когнитивном искажении «Избегание неопределенности» (подробнее)

        Что делать:
        Постарайтесь прояснить положение вещей.
        1) Сравните предмет обсуждения с чем-то знакомым для коллег. Приведите примеры, аналогии из другой сферы. Примеры помогают быстро уловить главный смысл. 
        2) Отвечайте на каждый заданный вопрос. Любой вопрос, оставшись без ответа, может тормозить процесс понимания для ваших собеседников. 
        3) Задайте коллегам встречный вопрос или спросите их мнения. Тогда оппонентам придется самим продумывать ситуацию, и она не будет казаться им такой неопределенной, как раньше.`,
        long: `Вам кажется, что всё уже проговорено и объяснено, но у коллег остаются вопросы? Дело в том, что при принятии решений все мы стремимся избегать риска и неопределенности, этот механизм заложен в нас эволюционно. 
        Возможно, для ваших коллег решение, которое вы предлагаете, пока ещё выглядит как неопределенность. 
        
        Пример: фондовые трейдеры, как правило, предпочитают инвестировать ценные бумаги в отечественные компании только потому, что они знакомы с ними, несмотря на то, что международные рынки предлагают аналогичные альтернативы, а иногда даже лучшие.`,
    },
    {
        name:"Эффект ложного консенсуса",
        title:"Эффект ложного консенсуса",
        short: `Собеседники выражают понимание и согласие? Остерегайтесь когнитивного искажения «эффект ложного консенсуса» (подробнее)

        Что делать:
        1) Если вы ещё не резюмировали ключевые вещи, то лучше дополнительно прояснить ситуацию. 
        2) Проговорите важные нюансы, задайте коллегам уточняющие вопросы, которые помогут понять, насколько верно воспринято ваше сообщение.`,
        long: `Когнитивное искажение «Эффект ложного консенсуса» связано с тем, что приходя к соглашению мы полагаем, что наш визави вкладывает в слова тот же смысл, что и мы сами, но зачастую это оказывается не так. 

        Пример: Руководитель поставил задачу: внести корректировки в документ. Сотрудник понял задачу и внёс правки в текст, не сохранив исходный вариант. Руководитель ожидал, что правки будут в виде комментариев с сохранением исходного текста.`,
    },
    {
        name:"Предвзятость подтверждения",
        title:"Предвзятость подтверждения",
        short: `В ходе совещаания были выдвинуты предложения дальнейших действий. Существует опасность поддаться «тенденциозности мышления» (подробнее)

        Что делать:
        Прежде чем быстро отвергнуть или согласиться с предложением, попробуйте найти в нём 5 плюсов и 5 минусов. Это позволит обнаружить новые элементы в уже знакомой ситуации и, возможно, изменить своё представление о ней. Так вы сможете принять более оптимальное решение.`,
        long: `Вам предложили способ решения. Наверняка вы и сами уже планировали варианты возможных действий. Поэтому есть вероятность поддаться эффекту тенденциозности. Т.е. быстро принять предложения оппонентов, если они согласуются с вашей точкой зрения, либо быстро отвергнуть их предложения, если они не согласуются с вашим видением ситуации. 

        Пример: Исследование, в котором у респондентов формировали убеждение:
        1) в первой группе, что пожарные склонные к риску более успешно справляются с профессиональными обязанностями; 
        2) во второй группе - обратное убеждение, что более осторожные пожарные более успешны в профессии.
        `,
        // Затем испытуемым сообщали, что аргументы были сфабрикованы, и на самом деле не существует корреляции между склонностью к риску и профессиональной успешностью. Авторы исследования обнаружили, что стойкость убеждения респондентов сохранилась даже после развенчания мифа.
    },
    {
        name:"Эффект сверхуверенности",
        title:"Эффект сверхуверенности",
        short: `Участники совещания выражают безусловную уверенность. Есть опасность когнитивного искажения «Эффект сверхуверенности» (подробнее)

        Что делать:
        1) Критически относитесь к любым прогнозам.
        2) В планировании всегда продумывайте самый пессимистичный сценарий - так появится возможность быть готовыми к неожиданностям и предупредить возможные ошибки.`,
        long: `Участники совещания делают прогнозы? Можно довериться экспертной оценке, но даже эксперты зачастую ошибаются и это нельзя сбрасывать со счетов. Тому виной когнитивное искажение “эффект сверхуверенности”: говорим «уверен на 99%», ошибаемся в 40% случаев.

        Пример: 
        Кристенсен-Залански и Бушихед [Christensen-Szalanski и Bushyhead, 1981] опросили группу врачей на предмет вероятности пневмонии у 1531 пациента с кашлем. В наиболее точно указанном интервале уверенности с заявленной достоверностью в 88 %, доля пациентов, действительно имевших пневмонию, была менее 20 %. Т.е. врачи, уверенные на 88%, оказались правы лишь в 20% случаев.`,
    },
    {
        name:"Недооценка бездействия",
        title:"Недооценка бездействия",
        short: `Прозвучало предложение отложить решение. Существует опасность когнитивного искажения «Недооценка бездействия» (подробнее)

        Что делать:
        1) Вместе с коллегами оцените, какие негативные последствия будут в случае откладывания решения? Как будут развиваться события, если решение вовсе не будет принято? 
        2) Можно принять промежуточное решение. Это позволит оценить выбранное направление, и в последствии принять окончательное решение.`,
        long: `Прозвучало предложение отложить решение, взять тайм-аут, ещё подумать? Когда мы не уверены, что действия приведут к хорошему результату, нам кажется, что лучше ничего не делать. Действительно, перед принятием важного решения нужно дать время мозгу взвесить все факты. Но большинство людей склонны к тенденции недооценивать последствия бездействия.

        Пример: В 2009 году в Австрии был закон, что граждане по умолчанию согласны на использование своих органов для пересадок в случае смерти. Можно было отказаться, но для этого надо было писать заявление. Только 0.02% людей это делали. 
        В Германии была обратная система — чтобы стать донором, нужно было изъявить желание. Поэтому 88% граждан не становились донорами.`,
    },
    {
        name:"Проклятие знания",
        title:"Проклятие знания",
        short: `Прозвучало предложение отложить решение, взять тайм-аут, ещё подумать? Когда мы не уверены, что действия приведут к хорошему результату, нам кажется, что лучше ничего не делать. Действительно, перед принятием важного решения нужно дать время мозгу взвесить все факты. Но большинство людей склонны к тенденции недооценивать последствия бездействия.

        Пример: В 2009 году в Австрии был закон, что граждане по умолчанию согласны на использование своих органов для пересадок в случае смерти. Можно было отказаться, но для этого надо было писать заявление. Только 0.02% людей это делали. 
        В Германии была обратная система — чтобы стать донором, нужно было изъявить желание. Поэтому 88% граждан не становились донорами.`,
        long: `Более информированным людям чрезвычайно сложно рассматривать какую-либо проблему с точки зрения менее информированных людей.
        Мы невольно, и порой вопреки всякой логике, «перекладываем» в голову стороннего наблюдателя свои собственные знания, которыми тот явно не располагает. Это приводит к недопониманию, неверной трактовке передаваемых друг другу идей, напряжению в отношениях и неоправданным ожиданиям.
        
        Пример: Программисты часто употребляют термины и сложные выражения, которые не всегда понятны их заказчику, т.к. забывают, что уровень знаний заказчика в программировании сильно отличается от их собственного.`,
    }
]