
import React from 'react';
import {
    Container, Row, Col, 
    // Cell, CellIcon,
    Underline,
    Header,
    Card,
    CardBody,
    CardContent,
    CardMedia,
    // CardHeadline1,
    CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    TextBoxSubTitle,
    CarouselGridWrapper,
    Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    Tabs, TabItem,

    // CarouselSection,
    CarouselCol,
    // MusicCard,

    

    Badge,
    useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import {
    IconApps,
    IconCalendar,
 } from '@sberdevices/plasma-icons';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory
  } from "react-router-dom";

import moment from 'moment'
import 'moment/min/locales';
// import momentDurationFormatSetup from 'moment-duration-format'

import { primary } from '@sberdevices/plasma-tokens';

import { cardStatus, microTypographer } from '../api/meet.js'

// momentDurationFormatSetup(moment)


function SmallMeetItem( props ) {
    let item = props.item
    let history = useHistory();
    
    // let handleClick = (id) => {
        //     history.push("/meetings/" + id)
        // }

    let status = cardStatus(item)
        
    return (
        <Card
            style={{ background: '#00000030' }}
            tabIndex={1}
            outlined={true}
            scaleOnFocus={true}
            onClick={()=>history.push("/meetings/" + item.id)}
        >
            <CardBody>
                <CardContent cover={false}>
                    <CardParagraph1 lines={4}>
                        {item.meeting_type.type}
                    </CardParagraph1>
                    <TextBox>
                        <TextBoxBigTitle>{microTypographer(item.meeting_core)}</TextBoxBigTitle>
                        {/* <TextBoxBiggerTitle>{item.meeting_core}</TextBoxBiggerTitle> */}
                        {/* <TextBoxSubTitle>{item.meeting_points.join('; ')}</TextBoxSubTitle> */}
                    </TextBox>
                    <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                        <Badge text={'Решено задач — '+item.meeting_points.filter(x=>x.status == 'done').length+' / ' + item.meeting_points.length } view={'clear'} style={{paddingLeft: 0, marginLeft:'-0.25rem'}}></Badge>
                        
                    </CardParagraph1>
                    <CardParagraph1 lines={4}>
                        {/* {item.meeting_agreed_datetime} */}
                        <Badge text={ status.title } view={status.view} style={{display:'inline'}}></Badge> 
                        <Badge text={ status.active_mini  } view={'clear'}  style={{display:'inline'}}></Badge> 
                        {/* <br/> */}
                        {/* <br/> */}
                        {/* <Badge text={ status.end.calendar() } view={'clear'}  style={{display:'inline'}}></Badge>  */}
                    </CardParagraph1>

                </CardContent>
            </CardBody>
        </Card>
    )
}

function MeetItem( props ) {
    let item = props.item
    let history = useHistory();
    
    // let handleClick = (id) => {
    //     history.push("/meetings/" + id)
    // }

    let status = cardStatus(item)

    let cardStyle = {
        transition: 'all 0.4s ease-in-out'
    }
    if (status.type == 'GOES') {
        // cardStyle.background = '#2a582d'
        // cardStyle.backgroundImage = 'linear-gradient(337deg, rgb(51, 134, 192) 0%, rgb(48 130 11) 50%, rgb(251 255 29) 100%)';
        cardStyle.backgroundImage = 'linear-gradient(337deg, rgb(51, 134, 192) 0%, rgb(33 110 78) 50%, rgb(34 173 96) 100%)';
        // cardStyle.backgroundImage = 'linear-gradient(-23deg, rgb(51 134 192) 0%, rgb(93 173 59) 100%)';
        // cardStyle.backgroundImage = 'linear-gradient(337deg, rgba(51 134 192 0.5) 0%, rgb(93 173 59) 100%)';
        cardStyle.boxShadow = '0 0.5em 1.5em rgb(59 103 94)';
    } else {
        cardStyle.background = '#00000000'
        cardStyle.boxShadow = 'none'
    }

    return (
        <Card
            // style={{ width: '22.5rem' }}
            // style={{ background: '#00000000' }}
            style={cardStyle}
            tabIndex={1}
            outlined={true}
            scaleOnFocus={true}
            onClick={()=>history.push("/meetings/" + item.id)}
        >
            <CardBody>
                {/* <CardMedia
                    // src={coverImgUrl}
                    // placeholder={coverImgUrl}
                    ratio={'1 / 2'}
                    />
                */ }
                <CardContent cover={false}>
                    <TextBox>
                        <TextBoxBigTitle>{item.meeting_type.type}</TextBoxBigTitle>
                        <TextBoxBiggerTitle>{microTypographer(item.meeting_core)}</TextBoxBiggerTitle>
                        {/* <TextBoxSubTitle lines={2}>{item.meeting_points.join('; ')}</TextBoxSubTitle> */}
                        <br/>
                        <CardParagraph1 style={{opacity: 0.5, fontSize:"0.9rem", lineHeight: '1.2rem'}} lines={3}>
                            {item.meeting_points.map(x=>x.description).join('; ')}
                        </CardParagraph1>
                        <br/>
                        
                    </TextBox>
                    <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                        { status.datatime_plus_d }
                        <br/>
                        Задач — {item.meeting_points.filter(x=>x.status == 'done').length} / {item.meeting_points.length}
                    </CardParagraph1>
                    <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                        {/* {item.meeting_agreed_datetime} */}
                        { status.active_full }

                        <br/>
                        <br/>

                        <Badge text={ status.title } view={status.view} style={{display:'inline'}}></Badge> 
                        &nbsp;
                        &nbsp;
                        {/* { status.begin.calendar() } — {status.d.as('minutes')} минут */}
                        {/* { status.begin.calendar() } — {status.d.hours()} ч {status.d.minutes()} м */}
                        {/* { status.begin.calendar() } — {status.d_txt} */}
                        <br/>
                        {/* { status.begin.calendar() } — { status.d.humanize() }  */}
                        {/* { status.begin.calendar() } — { moment.duration( status.d.asMinutes(), 'minutes').format() }  */}
                    </CardParagraph1>

                    {/* <Link to={'/meetings/' + item.id} component={Btn}></Link> */}
                    {/* <br /> */}

                    {/* <Button as="a" href={'/meetings/' + item.id} view="primary" size="s" style={{textDecoration:'none'}}>Детали</Button> */}
                    {/* <Button onClick={()=>history.push("/meetings/" + item.id)} view="primary" size="s" style={{textDecoration:'none'}}>Детали</Button> */}

                    {/* <Button
                        text="Label"
                        view="primary"
                        size="s"
                        scaleOnInteraction={false}
                        outlined={true}
                        fullWidth
                        style={{ marginTop: '1em' }}
                        tabIndex={-1}
                    /> */}
                </CardContent>
            </CardBody>
        </Card>
    )
}


function ActiveCorusel(props) {
    return (
        <CarouselGridWrapper>
            <Carousel as={Row} axis={'x'} scrollAlign={'start'} scrollSnapType={'mandatory'}
                style={{ paddingTop: '1rem', paddingBottom: '2rem' }}
                // paddingStart="50%" paddingEnd="50%"
            >
                <CarouselCol style={{width:'375px'}} scrollSnapAlign={'start'}> 
                    <h1 style={{lineHeight:'110%'}}>Сегодня отличный день, нас&nbsp;ждут великие&nbsp;дела!</h1>
                </CarouselCol>

                { props.list.filter(x=>cardStatus(x).type != 'ENDED')
                .sort((a,b)=> moment(a.meeting_agreed_datetime) - moment(b.meeting_agreed_datetime)).map( (x,index) => (
                    // <Col style={{marginBottom:'20px', width:'240px'}} key={index} > 
                    <CarouselCol style={{width:'390px'}} key={index} scrollSnapAlign={'start'}> 
                        <MeetItem item={x} />
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
}

function EndedCorusel(props) {
    return (
        <CarouselGridWrapper>
            <Carousel as={Row} axis={'x'} scrollAlign={'start'} scrollSnapType={'mandatory'}
                style={{ paddingTop: '1rem', paddingBottom: '2rem' }}
                // paddingStart="50%" paddingEnd="50%"
            >
                <CarouselCol style={{width:'375px'}} scrollSnapAlign={'start'}> 
                    {/* <h3>Прошедшие</h3> */}
                    <p>Немного статистики</p>
                </CarouselCol>
                { props.list.filter(x=>cardStatus(x).type === 'ENDED')
                    .sort((a,b)=> moment(b.meeting_agreed_datetime) - moment(a.meeting_agreed_datetime)).map( (x,index) => (
                    // <Col style={{marginBottom:'20px', width:'240px'}} key={index} > 
                    <CarouselCol style={{width:'285px'}} key={index} scrollSnapAlign={'start'}> 
                        <SmallMeetItem item={x} />
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
}


export default function MeetCards (props) {
    return (<>
        <Row> 
            <Col style={{width:'375px'}}></Col>
            <Col>
                <Underline style={{margin: '0rem 1rem 0'}}>БЛИЖАЙШИЕ</Underline>
                <br/>
                <br/>
                { props.list.filter(x=>cardStatus(x).type != 'ENDED')
                        .sort((a,b)=> moment(a.meeting_agreed_datetime) - moment(b.meeting_agreed_datetime))
                        .splice(0,1)
                        .map( (x,index) => (
                            // <Col style={{marginBottom:'20px', width:'240px'}} key={index} > 
                            // <CarouselCol style={{width:'390px'}} key={index} scrollSnapAlign={'start'}> 
                            <div style={{width:'30vw', margin: '0 auto'}}>
                                <MeetItem item={x} />
                            </div>

                            // </CarouselCol>
                        ))}
            </Col>
        </Row>
        {/* <ActiveCorusel list={[ ...props.list, ...props.list, ...props.list, ...props.list, ...props.list]}></ActiveCorusel> */}
        {/* <ActiveCorusel list={props.list}></ActiveCorusel> */}

       

    </>)
}