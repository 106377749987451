// todo : https://github.com/muaz-khan/RecordRTC/blob/master/simple-demos/auto-stop-on-silence.html

import Recorder from './lib/recorder.js'
// import hark from './lib/hark.js'
import hark from 'hark' 
// import Recorder from 'recorder-js'

import api from '../../api/api.js'


export default class RecorderList {
	gumStream;  // stream from getUserMedia()
	rec;        // Recorder.js object
	input;      // MediaStreamAudioSourceNode we'll be recording
	_iid;		// Interval id
	list = [];

	// shim for AudioContext when it's not avb.
	AudioContext = null;
	speechEvents = null; // hark.js object

	isInit = false
	isNowStageRecording = false;
	isCommand = false
	isStarted = false

	meeting_id = null

	constructor(meet_id = -1, fragments = []) {
		this.AudioContext = (window.AudioContext || window.webkitAudioContext)
		// api.get_meeting_def_fragments().then(res => {
		// 	this.list = res;
		// 	this.onChange()
		// });

		console.log('RL::constructor', meet_id, fragments)

		this.meeting_id = meet_id
		this.list = this.load_fragments(fragments)

		this.onChange();
	}

	load_fragments(frags) {
		return frags.map(x => {
			// "id": "9ce44341acc11f061515fec83c03aa1b",
			// "text": "Музыку и скринкаст готов мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на русском, то что встреча",
			// "is_minute": true,
			// "KI": [
			//   {
			// 	"type": "предвзятость подтверждения",
			// 	"KI_example": "просто",
			// 	"KI_real_text": "... мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на..."
			//   }
			// ]
			// let item = {

			// }
			return {
				// id: this.list.length,
				id: x.id,
				status: 'FROM_SERVER',
				statusHistory: ['FROM_SERVER'],
				// datetime_start: new Date(),
				
				isCommand: x.is_minute,
				server_id: x.id,
				url: api.get_audio_url(this.meeting_id, x.id),
				recognize: {
					text: x.text,
					KIs: x.KI
				}
			}

			// stopped_item.status = 'STOP_MIC';
			// stopped_item.statusHistory.push('STOP_MIC');
			// stopped_item.status = 'IN_RECOGNIZE';
			// stopped_item.statusHistory.push('IN_RECOGNIZE');
			// stopped_item.status = 'MIC_FAIL';
			// stopped_item.statusHistory.push('MIC_FAIL');
			// stopped_item.status = 'END';
			// stopped_item.statusHistory.push('END');
			// stopped_item.status = 'RECFAIL';
			// stopped_item.statusHistory.push('RECFAIL');
			// stopped_item.blob = blob;
			// stopped_item.url = (window.URL || window.webkitURL).createObjectURL(blob);
			// // stopped_item.filename = new Date().toISOString() + '.wav';
			// // stopped_item.filename, 
			// stopped_item.recognize = res
			// stopped_item.recognize.fragment = res.cur_fragment

		})
	}

	init() {
		console.log("RL::init");
		// this.isCommand = false

		/*
			Simple constraints object, for more advanced audio features see
			https://addpipe.com/blog/audio-constraints-getusermedia/
			https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
		*/

		let constraints = { audio: true, video:false }

		return navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
			// console.log("RL::getUserMedia() success, stream created, initializing Recorder.js ...");
			
			/*
				create an audio context after getUserMedia is called
				sampleRate might change after getUserMedia is called, 
				like it does on macOS when recording through AirPods
				the sampleRate defaults to the one set in your OS for your playback device
			*/
			
			let _audioContext = new this.AudioContext();

			this.speechEvents = hark(stream, {
				// smoothing: 0.1, 	// def: 0.1,
				// interval: 50, 	// def: 50,
				// threshold: -50, 	// def: -50,
				// play: false, 	// def: false,
				history: 10, 		// def: 10,
				// running: true, 	// def: true,
			});
			this.speechEvents.on('speaking', this.onSpeechEvents_Speaking)
			this.speechEvents.on('stopped_speaking', this.onSpeechEvents_StoppedSpeaking)
			this.speechEvents.on('volume_change', this.onSpeechEvents_VolumeChange)


			//  assign to gumStream for later use
			this.gumStream = stream;

			// use the stream 
			this.input = _audioContext.createMediaStreamSource(stream);

			/*
				Create the Recorder object and configure to record mono sound (1 channel)
				Recording 2 channels will double the file size
			*/
			this.rec = new Recorder( this.input, { numChannels:1 } )

			// start record
			this.isInit = true;

		}).catch(function(err) {
			//enable the record button if getUserMedia() fails
			console.log('fail getUserMedia', err)
			this.isInit = false;
		});
	}

	onSpeechEvents_Speaking = () => {
		console.log('RL:: .speechEvents голос')
		// this.onHaltInterval()
	}
	onSpeechEvents_StoppedSpeaking = () => {
		console.log('RL:: .speechEvents тишина')
		if (!this.isCommand) {
			this.onHaltInterval()
		}
	}
	onSpeechEvents_VolumeChange = (v) => {
			// console.log('speechEvents volume_change', v)
			// -100 = тишина
			// -50 = норм речь
			// 0 = очень громко
			let v1 = (v+100)/100
		this.onVolume( v1*v1*1.5 * 95 + 5 )
	}

	onHaltInterval() {
		console.log('RL::i')
		this._stopRecordingFragment();
		this._startRecordingFragment();

		clearTimeout(this._iid);
		if (this.isStarted && !this.isCommand) {
			this._iid = setTimeout( () => { 
				console.log('RL:: === Timeout === ')
				this.onHaltInterval() 
			}, this.max_interval )
		}
	}

	beginCommand() {
		if (!this.isStarted) return
		this.isCommand = true
		this.onHaltInterval();
	}
	endCommand() {
		if (!this.isStarted || !this.isCommand) return
		this.isCommand = false
		this.onHaltInterval();
	}

	startRecording(meeting_id, max_interval = 10000, min_interval = 5000, isCommand = false) {
		// if (this.isStarted) return Promise.reject('Уже запущен')
		if (this.isStarted) return Promise.resolve('Уже запущен')
		console.group('RL::START RECORDING', isCommand)
		// console.log('RL::START RECORDING')
		this.isStarted = true;
		this.max_interval = max_interval;
		this.min_interval = min_interval;
		this.meeting_id = meeting_id;
		this.isCommand = isCommand;
		return this.init().then((res) => {
			this.onHaltInterval();
			return res
		})
	}

	stopRecording() {
		if (!this.isStarted) return Promise.reject('Еще не запущен')
		console.log('RL::STOP RECORDING')
		console.groupEnd()
		clearTimeout(this._iid);
		let prom = this._stopRecordingFragment()?.then(() => {
			this.rec.worker.terminate();
			return Promise.resolve(true)
		});
		
		this.speechEvents?.stop();
		this.isCommand = false;
		
		
		//stop microphone access
		this.gumStream?.getAudioTracks()[0]?.stop();

		this.isStarted = false;

		return prom || Promise.resolve(true)
	}

	_current_item_fragment = null;

	_startRecordingFragment() {
		if (!this.isStarted || !this.isInit || this.isNowStageRecording) return
		this.isNowStageRecording = true;
		console.log('RL:: _start_fragment')
		
		this.rec.clear()

		// start the recording process
		this.rec.record()


		this._current_item_fragment = this.createItemList()
	}

	_stopRecordingFragment() {
		if (!this.isStarted || !this.isInit || !this.isNowStageRecording) return //Promise.reject('Нечего останавливать')
		this.isNowStageRecording = false;
		console.log('RL:: _stop_fragment')

		let stopped_item = this._current_item_fragment;
		stopped_item.status = 'STOP_MIC';
		stopped_item.statusHistory.push('STOP_MIC');
		this.onChange();

		return new Promise((resolve, reject) => {
			try {
				//tell the recorder to stop the recording
				this.rec.stop();
		
				//create the wav blob and pass it on to createItemList
				this.rec.exportWAV((b) => {
					// this.itemListAddWav(stopped_item, b)
					resolve( b )
				});
			} catch(err) {
				reject(err)
			}
		}).then((blob) => {
			
			stopped_item.blob = blob;
			stopped_item.url = (window.URL || window.webkitURL).createObjectURL(blob);
			stopped_item.filename = new Date().toISOString() + '.wav';
			stopped_item.status = 'IN_RECOGNIZE';
			stopped_item.statusHistory.push('IN_RECOGNIZE');
	
			this.onChange()
			return api.sendToRecognize(
				this.meeting_id, 
				blob, 
				stopped_item.filename, 
				stopped_item.isCommand
			)
		}, err => {
					console.log('RL::EXPORTWAV_ERR', err)
					stopped_item.status = 'MIC_FAIL';
					stopped_item.statusHistory.push('MIC_FAIL');
					this.onChange()
		}).then(res => {
			// audio_id: "dfdfa12478b1ffb4b9f259c24cc79e69"
			// cur_fragment: "Очень важная фраза"
			// distortions: []
			// is_minute: 1
			// prev_fragment: ""
			stopped_item.recognize = res
			stopped_item.recognize.text = res.cur_fragment
			stopped_item.recognize.KIs = res.distortions
			stopped_item.status = 'END_v1';
			stopped_item.statusHistory.push('END_v1');

			let prev_item_index = this.list.findIndex(x => x === stopped_item) -1
			if (prev_item_index > -1 && this.list[prev_item_index]) {
				if (this.list[prev_item_index].recognize) {
					this.list[prev_item_index].recognize.text_v2 = res.prev_fragment
					this.list[prev_item_index].status = 'END_v2';
					this.list[prev_item_index].statusHistory.push('END_v2');
				} else {
					console.log('v2 before v1?', this.list[prev_item_index])
					this.list[prev_item_index].recognize = { KIs: [], text: ''}
					this.list[prev_item_index].recognize.text_v2 = res.prev_fragment
					this.list[prev_item_index].status = 'END_v2_before_v1';
					this.list[prev_item_index].statusHistory.push('END_v2_before_v1');
				}
			}

			this.onChange()
		}, err => {
					console.log('RL::RECOGNIZE_ERR', err)
					stopped_item.status = 'RECOGNIZE_FAIL';
					stopped_item.statusHistory.push('RECOGNIZE_FAIL');
					this.onChange()
		});

	}

	createItemList() {
		let item = {
			id: this.list.length,
			status: 'IN_MIC',
			statusHistory: ['IN_MIC'],
			datetime_start: new Date(),
			isCommand: this.isCommand,
		}
		this.list.push(item)
		this.onChange()
		return item
	}

	clear() {
		this.list = []
        this.onChange();
	}

	addChangeListener(f) {
		document.addEventListener('onChangeRecorderList', f);
	}
	removeChangeListener(f) {
		document.removeEventListener('onChangeRecorderList', f);
	}
	onChange() {
		// console.log('RecorderList:Change', this.list)
		var event = new CustomEvent("onChangeRecorderList", {
			detail: {
				list: this.list
			}
		});
		document.dispatchEvent(event);
	}

	addVolumeListener(f) {
		document.addEventListener('onVolumeRecorderList', f);
	}
	removeVolumeListener(f) {
		document.removeEventListener('onVolumeRecorderList', f);
	}
	onVolume(v) {
		// console.log('RecorderList:Volume', this.list)
		var event = new CustomEvent("onVolumeRecorderList", {
			detail: {
				v: v
			}
		});
		document.dispatchEvent(event);
	}

}


