
import React, { useState } from 'react';

import {
    Container, 
    Row, Col, 
    // Cell, CellIcon, CellListItem,
    Header,

    // HeaderRoot,
    // HeaderBack,
    // HeaderLogo,
    // HeaderTitleWrapper,
    // HeaderTitle,
    // HeaderSubtitle,
    // HeaderContent,

    Card,
    // CardBody,
    CardContent,
    // CardMedia,
    // CardHeadline1,
    CardParagraph1,
    // TextBoxBigTitle,
    // TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // TextBoxTitle,
    Badge,
    Tabs, TabItem,
    Display2,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    // TextBox,
    Button,
    // ActionButton,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Underline,
    // Spinner
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';



 import {
//     IconEye,
//     IconSleep,
        // IconMic,
        // IconMicNone,
        // IconMicOff,
        IconPersone,
        // IconAlarm,
        IconHouseSbol,
        IconCardstack,
        IconMessage,
        IconInfo,
        IconSettings,
        // IconKeyboard,
        // IconTrash,
        IconFeedback,
        // IconSpinner,
        IconClose,
 } from '@sberdevices/plasma-icons';

 import { Progress, notification } from 'antd';

 import { 
     ThunderboltFilled, 
     PieChartFilled, 
     SignalFilled, 
     PushpinFilled, 
     MailOutlined,
     FieldTimeOutlined
} from '@ant-design/icons';

//  LoadingOutlined

import RecorderList from './rec/RecorderList.js'
import { cardStatus, microTypographer } from '../api/meet.js'

import './MeetingPage.css'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory
  } from "react-router-dom";

import { Modal } from 'antd';
import { Row as Row2, Col as Col2 } from 'antd';
  

 
import InfoPanel from './InfoPanel.js'
// import ki from '../dict/ki.js'
import KIsPanel from './KIsPanel.js'
import TasksPanel from './TasksPanel.js'
import PeoplePanel from './PeoplePanel.js'
import StatPanel from './StatPanel'
import PathPanel from './PathPanel'
import PathPanel2 from './PathPanel2'

import api from '../api/api.js'

import { 
    PlayStopBtn, 
    ClearHistBtn, 
    TextRecList, 
    // SetDemo, 
    // AboutKI, 
    ReportRecList,
    // VolumeLevel
} from './rec'

// import { overlay } from '@sberdevices/plasma-tokens/lib/colors/values';
// import { animatedScrollToX } from '@sberdevices/ui/utils';

// const coverImgUrl = 'https://5f96ec813d800900227e3b93-sdshhvdjxe.chromatic.com/images/320_320_0.jpg';




// style={{ width: '22.5rem', marginRight: '20px' }}

let MyTabs = (props) => {
    let items = props.items

    // const [index, setIndex] = React.useState(props.index);
    let index = props.index

    let setInd = (id) => {
        // setIndex(id)
        props.onChange(id)
    }

    return (
        <Tabs
            size={'l'}
            // view={'secondary'}
            view={'clear'}
            // fixedWidth={true}
            pilled={false}
            scaleOnPress={true}
            outlined={false}
            style={props.style}
            
        >
            {Object.keys(items).map((_, i) => (
                <TabItem
                    key={i}
                    
                    isActive={_ === index}
                    tabIndex={i}
                    style={{opacity: _ === index ? 1: 0.4}}
                    contentLeft={items[_]}
                    onClick={() => setInd(_)}
                >
                    {/* {_ === index ? _ : ''} */}
                    {/* {props.index} */}
                </TabItem>
            ))}
        </Tabs>
    );
}


// let MyTabsVert = (props) => {
//     let items = props.items

//     // const [index, setIndex] = React.useState(props.index);
//     let index = props.index

//     let setInd = (id) => {
//         // setIndex(id)
//         props.onChange(id)
//     }

//     return (
//         <Tabs
//             size={'l'}
//             // view={'secondary'}
//             view={'clear'}
//             // fixedWidth={true}
//             pilled={false}
//             scaleOnPress={true}
//             outlined={false}
//             style={{ 
//                 flexFlow: 'column', 
//                 position: 'absolute',
//                 left: '30px',
//                 top: '140px',
//                 // justifyContent: 'center',
//                 // height: '100%'
//             }}
//         >
//             {Object.keys(items).map((_, i) => (
//                 <TabItem
//                     key={i}
//                     isActive={_ === index}
//                     tabIndex={i}
//                     style={{opacity: _ === index ? 1: 0.4}}
//                     contentLeft={items[_]}
//                     onClick={() => setInd(_)}
//                 >
//                     {/* {_ === index ? _ : ''} */}
//                 </TabItem>
//             ))}
//         </Tabs>
//     );
// }


export class TimeDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: new Date(),
            showLastAlert: false,
            showRedText: false,
            showEnd: false,
            showStart: false,
        }
    }
    componentDidMount() {
        this._iid = setInterval(() => this.update_now(), 1000);
        this.update_now();
    }
    componentWillUnmount() {
        clearInterval(this._iid);
    }
    update_now() {
        // console.log('T')
        this.setState({ now: new Date() })

        return

        let status = cardStatus(this.props.meet);
        // console.log('left M', status.left_minutes)
        if ( status.left_minutes < 1 && status.left_minutes > 0.9 && this.state.showLastAlert == false) {
        // if (status.left_minutes < 1 && this.state.showLastAlert == false) {
            this.setState({ showLastAlert: true })
            notification.open({
                duration: 20,
                placement: 'bottomRight',
                message: 'До конца встречи 1 минута!',
                description: 'Не забудьте проверить себя по вспомогательным вопросам.',
                icon: <FieldTimeOutlined style={{color:'red'}} />,
                onClick: () => {
                    this.props.onClickLastAlert()
                }
                // description: <Badge>123</Badge>,
            });
            new Audio('/bip_1m.mp3').play()
        }
        if ( status.left_minutes < 2 && status.left_minutes > 1.9 && this.state.showRedText == false) {
            this.setState({ showRedText: true })
            new Audio('/bip_2m.mp3').play()
        }
        if ( status.left_minutes < 0 && status.left_minutes > -0.1 && this.state.showEnd == false) {
            this.setState({ showEnd: true })
            new Audio('/bip_0m.mp3').play()
        }
        if ( status.right_minutes < 0 && status.right_minutes > -0.1 && this.state.showStart == false) {
            this.setState({ showStart: true })
            new Audio('/bip_0m.mp3').play()
        }
    }
    render() {
        let now = this.state.now;
        let status = cardStatus(this.props.meet);
        // let lefttime = Math.round( (status.end - now) / 1000 / 60, 1 )
        let lefttime = status.display_active

        return (
            <div style={{
                    // textAlign: 'center', 
                    // justifyContent: 'flex-end',
                    // justifyContent: 'flex-begin',
                    // justifyContent: 'center',
                    flexFlow: 'row',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Display2 style={{display:'inline-block', fontSize:'40px'}}>
                    {now.getHours()}:{now.getMinutes() < 10 ? '0' : ''}{now.getMinutes()}
                </Display2>
                &nbsp;
                &nbsp;
                {/* <p>осталось <br/>{now.getSeconds()} минут</p> */}
                {/* <p>осталось <br/>{lefttime} минут</p> */}
                {/* <p style={{fontSize:'16px'}}>{lefttime}</p> */}
                <p style={{textAlign:'left', width:"120px", lineHeight:1.1}} >{lefttime}</p>
            </div>
        )

        return (
            <div style={{
                    // textAlign: 'center', 
                    // justifyContent: 'flex-end',
                    // justifyContent: 'flex-begin',
                    // justifyContent: 'center',
                    flexFlow: 'row',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                <Display2 style={{display:'inline-block'}}>
                    {now.getHours()}:{now.getMinutes() < 10 ? '0' : ''}{now.getMinutes()}
                </Display2>
                &nbsp;
                &nbsp;
                {/* <p>осталось <br/>{now.getSeconds()} минут</p> */}
                {/* <p>осталось <br/>{lefttime} минут</p> */}
                {/* <p style={{fontSize:'16px'}}>{lefttime}</p> */}
                <p style={{textAlign:'left', width:"90px", lineHeight:1.1}} >{lefttime}</p>
                &nbsp;
                &nbsp;
                &nbsp;
                
            </div>
        )

        return (
            <div style={{
                    flexFlow: 'column',
                    alignItems: 'right',
                    display: 'flex'
                }}>
                <h2 style={{display:'inline-block', margin:0, textAlign:'right'}}>
                    {now.getHours()}:{now.getMinutes() < 10 ? '0' : ''}{now.getMinutes()}
                </h2>
                <p style={{textAlign:'right', width:"190px", lineHeight:1.1, margin:0}} >{lefttime}</p>
            </div>
        )
    }
}


export class TimeProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: new Date(),
        }
    }
    componentDidMount() {
        this._iid = setInterval(() => this.update_now(), 1000);
        this.update_now();
    }
    componentWillUnmount() {
        clearInterval(this._iid);
    }
    update_now() {
        this.setState({ now: new Date() })
    }
    render() {
        let now = this.state.now;
        let status = cardStatus(this.props.meet);
        let percent = (now - status.begin) / (status.end - status.begin) * 100
        return (
            <div className="TimeProgress">
                { status.type == 'WILL_BE' ? 
                    <Progress strokeColor={'#ffffffaa'}
                        percent={100} showInfo={false}/>
                     :
                    <Progress strokeColor={{ '0%': '#87d068', '100%': '#108ee9' }}
                        percent={100-percent} showInfo={false}/>
                }
                
                {/* {status.begin.calendar()} —
                {status.d_txt} — 
                {status.end.calendar()} 
                &nbsp;&nbsp;&nbsp;
                <Badge text={ status.title } view={status.view} 
                    style={{display:'inline'}}
                    onClick={()=>{

        notification.open({
            // duration: 0,
            placement: 'bottomRight',
            message: 'title',
            description: <Badge>123</Badge>,
            onClick: () => {
                window.open('https://45.89.225.221:9100/get_meetings', '_blank').focus();
            },
            
        });

                    }}></Badge>
                { Math.round( (now - status.begin) / 1000 / 60, 1 ) } 
                /
                { status.d.asSeconds() / 60}  */}

            </div>
        )
    }
}

// var myrec = new RecorderList()

// source: https://stackoverflow.com/a/11331200/4298200
function Sound(source, volume, loop)
{
    this.source = source;
    this.volume = volume;
    this.loop = loop;
    var son;
    this.son = son;
    this.finish = false;
    this.stop = function()
    {
        document.body.removeChild(this.son);
    }
    this.start = function()
    {
        if (this.finish) return false;
        this.son = document.createElement("embed");
        this.son.setAttribute("src", this.source);
        this.son.setAttribute("hidden", "true");
        this.son.setAttribute("volume", this.volume);
        this.son.setAttribute("autostart", "true");
        this.son.setAttribute("loop", this.loop);
        document.body.appendChild(this.son);
    }
    this.remove = function()
    {
        document.body.removeChild(this.son);
        this.finish = true;
    }
    this.init = function(volume, loop)
    {
        this.finish = false;
        this.volume = volume;
        this.loop = loop;
    }
}


function AnyMeeting(props) {
    let { id } = useParams();
    let history = useHistory();
    let meet = props.list.find(x => x.id == id);
    // const [index, setIndex] = React.useState('path');
    const [index, setIndex] = React.useState('path2');
    
    // if (!meet) return (<h3>404 — Встреча не найдена </h3>);
    if (!meet) return (' ');

    // let reclist = myrec
    let reclist = meet.reclist

    console.warn('LOAD AnyMeeting',meet, reclist)
    // let reclist = new RecorderList(meet)

    
    return (
        <div className="MeetingPage vh100">
                        {/* <MyTabsVert items={{
                            info : <IconInfo />,
                            report : <PushpinFilled style={{fontSize:'22px'}} />,
                            person : <IconPersone />,
                            dialog : <IconMessage />,
                            // report : <IconCardstack />,
                            ki : <ThunderboltFilled style={{fontSize:'22px'}} />,
                            stat : <SignalFilled style={{fontSize:'20px'}} />,
                            // stat : <PieChartFilled style={{fontSize:'20px'}} />,
                            conf : <IconSettings />,
                        }} index={index} onChange={(i) => setIndex(i)} /> */}
                        
            <Container className="vh100">

                
                <TimeProgress meet={meet} />


                <Row2 gutter={60} align="middle" wrap={false} style={{marginBottom:'0'}}>
                    {/* <Col2 span={4}> */}
                    <Col2 span={6}>
                        <Header
                                back={true}
                                // logo={coverImgUrl}
                                // logoAlt="Logo"
                                title={<img src={"/logo_sdm.png"} width="120" />}
                                // title={meet.meeting_core}
                                // subtitle={meet.meeting_type.type + ' — ' + meet.status.datatime_plus_d}
                                onBackClick={() => history.push("/meetings")}
                                onClick={() => history.push("/meetings")}
                            />   
                        
                    </Col2>
                    <Col2 flex="auto">
                        <Header
                                style={{padding:0}}
                                back={false}
                                // logo={coverImgUrl}
                                logoAlt="Logo"
                                // title={'Встречи'}
                                title={microTypographer(meet.meeting_core)}
                                subtitle={meet.meeting_type.type + ' — ' + meet.status.datatime_plus_d}
                                onBackClick={() => history.push("/meetings")}
                                // onClick={() => history.push("/meetings")}
                            >  
                            
                            <div style={{marginLeft:'40px'}}>
                                <PlayStopBtn meet_id={meet.id} reclist={reclist} />
                            </div>
                        </Header>
                    </Col2>
                    {/* <Col2>
                    </Col2> */}
                    <Col2 flex="none" style={{textAlign:'right', paddingLeft:0}} >
                        <TimeDisplay meet={meet} reclist={reclist} onClickLastAlert={() => {
                                setIndex('info')
                            }} />
                    </Col2>
                </Row2>

                <Row2 gutter={60} align="middle" style={{marginBottom:'30px'}}>
                    <Col2 span={6} ></Col2>
                    {/* <Col2 span={4} ></Col2> */}
                    <Col2 flex="auto"> 
                        <MyTabs 
                            style={{marginLeft: 'calc(var(--tab-item-padding-y-reduced)*(-1))'}} 
                            items={{
                            path : <IconCardstack />,
                            path2 : <IconCardstack />,
                            info : <IconInfo />,
                            report : <PushpinFilled style={{fontSize:'22px'}} />,
                            person : <IconPersone />,
                            dialog : <IconMessage />,
                            // report : <IconCardstack />,
                            ki : <ThunderboltFilled style={{fontSize:'22px'}} />,
                            // stat : <SignalFilled style={{fontSize:'20px'}} />,
                            // stat : <PieChartFilled style={{fontSize:'20px'}} />,
                            conf : <IconSettings />,
                        }} index={index} onChange={(i) => setIndex(i)} />
                    </Col2>
                    <Col2 style={{textAlign:'right'}} ></Col2>
                </Row2>


                {/* <Row2 gutter={60} align="middle" style={{marginBottom:'30px'}}>
                    <Col2 span={8}>
                        <Header
                            back={true}
                            // logo={coverImgUrl}
                            logoAlt="Logo"
                            // title={'Встречи'}
                            title={meet.meeting_core}
                            subtitle={meet.meeting_type.type + ' — ' + meet.status.datatime_plus_d}
                            onBackClick={() => history.push("/sber_valli")}
                            onClick={() => history.push("/sber_valli")}
                        >                            
                        </Header>
                    </Col2>
                    <Col2 span={10}>
                        <TimeDisplay meet={meet} reclist={reclist} onClickLastAlert={() => {
                            setIndex('info')
                        }} />
                        <VolumeLevel meet={meet} reclist={reclist} /> 
                    </Col2>
                    <Col2 lg={6} md={24} style={{flexFlow: 'column', alignItems: 'flex-end', display: 'flex'}}>

                    </Col2>
                    <Col2 size={2} style={{ flexFlow: 'column', alignItems: 'flex-end', display: 'flex'}}><MyTabs /></Col2> 
                </Row2> */}
                
                


                        { ['info', 'person', 'report'].indexOf( index ) > -1 ? (
                            <Row2 gutter={60} className="rowContent h100">
                                {/* <Col size={2} sizeXL={6} sizeL={4} sizeS={4}> */}
                                {/* <Col size={4} style={{paddingLeft:'4rem'}}>                     */}
                                {/* <Col2 span={8}> */}
                                {/* <Col2 offset={4} span={7}> */}
                                <Col2 span={6}>
                                    { TasksPanel(meet) }
                                </Col2>

                                {/* <Col2 span={4}></Col2> */}
                                {/* <Col2 offset={4} span={14} style={{position:'relative'}}>                     */}
                                <Col2 span={18} style={{position:'relative'}}>                    
                                {/* <Col2 span={11} style={{position:'relative'}}>                     */}
                                    {/* <h1>{index}</h1> */}

                                    { index === 'info' ? InfoPanel(meet) : '' }
                                    { index === 'person' ? PeoplePanel(meet) : '' }
                                    { index === 'report' ? RenderPanelReport(meet, reclist) : '' }
                                </Col2>
                            </Row2>
                        ) : '' }

                        { ['dialog', 'ki', 'stat', 'conf', 'path', 'path2'].indexOf( index ) > -1 ? <>
                            <Row2 gutter={60} className="h100">
                                <Col2 span={24}>
                                {/* <Col2 offset={6} span={18} style={{position:'relative'}}> */}
                                {/* <Col2 offset={4} span={20}> */}
                                    {/* <h1>{index}</h1> */}
                                    { index === 'dialog' ? RenderPanelDialog(meet, reclist) : '' }
                                    { index === 'ki' ? KIsPanel(meet) : '' }
                                    { index === 'stat' ? StatPanel(meet) : '' }
                                    { index === 'conf' ? RenderPanelConf(meet) : '' }
                                    { index === 'path' ? <PathPanel meet={meet}/> : '' }
                                    { index === 'path2' ? <PathPanel2 meet={meet}/> : '' }
                                </Col2>
                            </Row2>
                        </> : '' }

        
            </Container>
        </div>
    );
}


let RenderPanelDialog = (meet, reclist) => {
    console.log('RenderPanelDialog', api.get_transcript(meet.meeting_id) )
    
    return (<>
        <Row2 gutter={60} className="">
            <Col2 offset={6} span={18}>
                <h1 style={{lineHeight: '1.2', marginTop:0 }}>Стенограмма</h1>
            </Col2>
        </Row2>
        <Row2 gutter={60} className="h100">
            <Col2 offset={6} span={18}>
            {/* <Col2 md={24} lg={8}> */}
                    <div className="scrollPanel">
                {/* <div style={{position:'relative'}} className="h100"> */}

                    {/* <p>Полный текст расшифровки встречи, с&nbsp;отметками главных тезисов для&nbsp;протокола  */}
                        {/* <br/>и&nbsp;возможных когнитивных искажений</p> */}
                    {/* <SetDemo reclist={reclist} /> &nbsp; */}
                    {/* <ClearHistBtn meet={meet} reclist={reclist} /> */}

                {/* </Col2> */}
                {/* <Col2 md={24} lg={16} style={{position:'relative'}}> */}
                        {/* <PlayStopBtn reclist={reclist} /> &nbsp; */}
                        {/* <br/> */}
                        {/* <br/> */}
                        <TextRecList reclist={reclist} />
                        <br/>
                        <br/>
                    </div>
                {/* </div> */}
            </Col2>
        </Row2>
    </>)
}


let RenderPanelReport = (meet, reclist) => {
    return (
        <div className="scrollPanel">
            <h1 style={{lineHeight: '1.2', marginTop:0}}>Протокол встречи</h1>
                   
            <ReportRecList meet={meet} reclist={reclist} />
        </div>
    )
}

let RenderPanelConf = (meet) => {
    return (
        <Row2 gutter={60} className="h100">
            <Col2 offset={6} span={18}>
            {/* <Col2 span={8}> */}
                <div className="scrollPanel">
                <h1 style={{lineHeight: '1.2', marginTop:0}}>Настройки</h1>
                <p>Выбор ваших предпочтений</p>
            {/* </Col2> */}
            {/* <Col2 md={24} lg={16} style={{position:'relative'}}> */}
                {/* <Card style={{height:'90%'}}> */}
                    {/* <CardContent compact style={{overflow: 'hidden', lineHeight:1.3, height:'100%'}}> */}
                        <small><pre>{ JSON.stringify(meet, null, '  ')}</pre></small>
                </div>
                    {/* </CardContent> */}
                {/* </Card> */}
            </Col2>
        </Row2>
    )
}


// export default MeetList();
export default AnyMeeting;