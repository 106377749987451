
import {
    Container, Row, Col, 
    // Cell, CellIcon,
    // Header,
    Card,
    CardBody,
    CardContent,
    CardMedia,
    // CardHeadline1,
    CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    TextBoxSubTitle,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    Badge
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    // Link,
    // useParams,
    useHistory
  } from "react-router-dom";

import { Calendar } from 'antd';



function getListData(value) {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        { type: 'warning', content: 'This is warning event.' },
        { type: 'success', content: 'This is usual event.' },
      ];
      break;
    case 10:
      listData = [
        { type: 'warning', content: 'This is warning event.' },
        { type: 'success', content: 'This is usual event.' },
        { type: 'error', content: 'This is error event.' },
      ];
      break;
    case 15:
      listData = [
        { type: 'warning', content: 'This is warning event' },
        { type: 'success', content: 'This is very long usual event。。....' },
        { type: 'error', content: 'This is error event 1.' },
      ];
      break;
    default:
  }
  return listData || [];
}

let item = {
    "id": "2_ABaOnudnzC3TYIdIMM8JbRZ5ZvqrpAHh2i8uW4zNFdfjRvQ4-ewG05w21LCYI4vDlXAVCU0",
    "meeting_type": {
        "type": "Поиск решения",
        "code": 2
    },
    "meeting_core": "Работа с курсом по Психотипам",
    "meeting_points": ["Добавить в образ результата:\n- надо научить директоров учить других\n- учить они будут в онлайне\n- научить их коммуницировать на основе психотипо", "Написать образовательные цели", "Поработать над программой - проапгрейдить предыдущую"],
    "meeting_participants": ["Амир Гиниятуллин, Лаборатория нейронаук"],
    "meeting_additional_participants": ["Екатерина Маслякова, Лаборатория нейронаук"],
    "meeting_wanted_window": {
        "before_date": "2021-02-25",
        "before_time": "19:00",
        "duration": "1,5 часа"
    },
    "meeting_agreed_datetime": "2021-02-24 19:00",
    "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
    "meeting_prepare": null
}

function MeetItem( props ) {
    // let item = props.item
    let history = useHistory();
    
    // let handleClick = (id) => {
    //     history.push("/meetings/" + id)
    // }

    let style = {
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        // maxWidth: '100%'
        height: 'auto',
        textAlign: 'left',
        marginBottom: '4px',
        fontWeight: 'normal',
    }
    // <Badge text={item.meeting_core} style={style} />
    return (
        <Button text={item.meeting_core} onClick={()=>history.push("/meetings/" + item.id)} style={style} size={'s'} view={'primary'} resizible={true} />
    )

    return (
        <Card
            style={{ width: 'auto' }}
            tabIndex={-1}
            outlined={true}
            scaleOnFocus={true}
        >
            <CardBody>
                <CardMedia 
                    // src={coverImgUrl}
                    // placeholder={coverImgUrl}
                    ratio={'1 / 2'}
                    />
                <CardContent cover={true}>
                    <TextBox>
                    {item.meeting_core}
                        {/* <TextBoxBigTitle>{item.meeting_type.type}</TextBoxBigTitle> */}
                        {/* <TextBoxBiggerTitle>{item.meeting_core}</TextBoxBiggerTitle> */}
                        {/* <TextBoxSubTitle>{item.meeting_points.join('; ')}</TextBoxSubTitle> */}
                    </TextBox>
                    {/* <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                        {item.meeting_agreed_datetime}
                    </CardParagraph1> */}

                    {/* <Link to={'/meetings/' + item.id} component={Btn}></Link> */}
                    {/* <br /> */}

                    
                    {/* <Button onClick={()=>history.push("/meetings/" + item.id)} view="primary" size="s" style={{textDecoration:'none'}}>Детали</Button> */}

         
                </CardContent>
            </CardBody>
        </Card>
    )
}


function dateCellRender(value) {
  const listData = getListData(value);
  // <ul className="events">
  return listData.map(item => (
        <div key={item.content}>
          {/* <Badge text={item.content} /> */}
          {/* {item.content} */}
          <MeetItem></MeetItem>
        </div>
      ));
      // </ul>
}

function getMonthData(value) {
  if (value.month() === 8) {
    return 1394;
  }
}

function monthCellRender(value) {
  const num = getMonthData(value);
  return num ? (
    <div className="notes-month">
      <section>{num}</section>
      <span>Backlog number</span>
    </div>
  ) : null;
}

export default (<>
    <style>
        {`
        .ant-picker-calendar,
        .ant-picker-calendar-full .ant-picker-panel { 
            background: transparent; }
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date { 
            border-top: 2px solid #ffffff14; }
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, 
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, 
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, 
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
            background: #0000002e; background: transparent; }
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
            height: auto; overflow: inherit; }
        .ant-picker-calendar-full tr {  
            vertical-align: top; }
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date { 
            min-height: 50px; padding: 0;
        }
        .ant-picker-cell {
            cursor: default;
        }
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
            color: inherit; 
        }
        .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
        .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
            background: transparent;
        }
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
            paddig: 0 5px 5px 0;
        }
        `}
    </style>
    <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} onSelect={res => {}} disabledDate={res => false} headerRender={({ value, type, onChange, onTypeChange }) => { return '' }} />
</>)

