
import axios from 'axios'
import { Profiler } from 'react';


// const io = require("socket.io-client");
// or with import syntax
// import { io } from "socket.io-client"; // v4
import socketio from "socket.io-client"; // v2.0.4

// import { notification } from 'antd';
// import { WarningOutlined } from '@ant-design/icons';

import { demo_meetings, demo_recognize, demo_stenography } from './demodata.js'
// import demodata from './demodata.js';

import { meet } from './meet.js' 


// https://45.89.225.221:9100/test_meeting
// https://45.89.225.221:9100/send_test_meetings
// https://45.89.225.221:9100/get_meetings

// let demo_mode = true
let demo_mode = window.CONFIG.api_demo_mode


// const api_url = 'https://45.89.225.221:8100/'; // api v1 - до авторизации
// const api_url = 'https://45.89.225.221:9100/'; // api v2 
const api_url = window.CONFIG.api_server; // api v2 


function urlWithParams(url, params) {
    return url + '?' + new URLSearchParams( params ).toString()
    // return '?' + Object.keys( params ).map( x => x + '=' + params[x]).join('&')
}


let demo_stenography_i = 0;

let ACCESS_TOKEN = ''
let user = {}

export default {
    set_demo_mode(val = true) {
        demo_mode = val
    },
    get_demo_mode() {
        return demo_mode
    },
    test_auth() {
        if (ACCESS_TOKEN == '') return Promise.resolve(false)
        return axios.get(api_url + 'get_updates').then(res => {
            return true
        }, err => {
            return false
        })
    },
    get_meetings_list() {

        if (demo_mode) {
            return new Promise((resolve, reject) => {
                // resolve( demo_meetings )
                resolve( demo_meetings.map(x => meet(x)) )
            })
        } else  {

            return axios.get(api_url + 'get_meetings').then((res) => {
                console.log('API::get_meetings', res.data)
                return res.data.map(x => meet(x))
            })
        }
        
    },


    get_transcript(meeting_id) {

        if (demo_mode) {
            return new Promise((resolve, reject) => {
                // resolve( demo_meetings )
                // resolve( demo_meetings.map(x => meet(x)) )
                resolve( [] )
            })
        } else  {

            return axios.get(api_url + 'get_transcript', {
                params: {
                    meeting_id: meeting_id
                }
            }).then((res) => {
                console.log('API::transcript', res.data)
                return res.data //.map(x => meet(x))
            })
        }
        
    },


    get_test_url() {
        return api_url + 'get_meetings';
    },

    _socket: null,
    _socket_connect() {
        // console.log('io', socketio)
        this._socket = socketio(api_url)      
    },

    get_socket() {
        if (demo_mode || 1) {
            return {on: (a) => {}}
        }
        console.log('get_socket')
        if (this._socket === null) this._socket_connect();
        return this._socket
    },

    clear_history_total_text(meet_id) {
        if (demo_mode) {
            return new Promise((resolve, reject) => {
                resolve( 'ok' )
            })
        }
        return axios.get(api_url + 'clear_total_text', {
            params: {
                meeting_id : meet_id
            }
        }).then(res => {
            console.log(res)
        })
    },

    recognize() {
        return api_url + "recognize"
    },

    get_meeting_def_fragments() {
        return new Promise((resolve, reject) => {
            // if (demo_mode) {
            //     resolve( demo_recognize )
            // } else {
                resolve( [] )
            // }
        })
    },


	sendToRecognize(meeting_id, blob, filename, is_minute = false) {

		// axios.post("http://45.89.225.221:9100/recognize", {
		// 	"audio_data": item.blob,
		// }).then(res => {
		// 	console.log('sendToRecognize', res)
		// 	item.text = res.data
		// 	this.onChange()
		// })

        return new Promise((resolve, reject) => {
            // resolve( demo_recognize )

            if ( demo_mode ) {
                let s = demo_stenography[demo_stenography_i++ % demo_stenography.length];
                setTimeout(()=>resolve( {
                    // distortions: ['sdfsdf'],
                    // fragment: 'demo recognize text',
                    // total_text: ''

                    // "id": "9ce44341acc11f061515fec83c03aa1b",
                    "cur_fragment": s.text,
                    // "is_minute": true,
                    "distortions": s.KI || [],
                    'prev_fragment': '',

                    // res.cur_fragment
                    // res.distortions
                    // res.prev_fragment

                    // "id": "9ce44341acc11f061515fec83c03aa1b",
                    // "text": "Музыку и скринкаст готов мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на русском, то что встреча",
                    // "is_minute": true,
                    // "KI": [{
                    //     "type": "Предвзятость подтверждения",
                    //     "KI_example": "просто",
                    //     "KI_real_text": "... мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на..."
                    // }]
                } ), 1000)
                return
            }

            var xhr = new XMLHttpRequest();
    
            xhr.onload = function(e) {
                if(this.readyState === 4) {
                    // console.log("Server returned: ", e.target.responseText);
                }
            };
    
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    // item.text = JSON.parse(xhr.responseText)
                    // this.onChange()
                    resolve( JSON.parse(xhr.responseText) )
                }
            };

            xhr.onerror = function(err) {
                reject(err)
            };
    
            var fd = new FormData();
            fd.append("audio_data", blob, filename);
            fd.append("meeting_id", meeting_id);
            fd.append("is_minute", is_minute ? 1 : 0 );
            // xhr.open("POST","https://45.89.225.221:9101/recognize",true);
            // xhr.open("POST","http://45.89.225.221:9101/recognize",true);
            // xhr.open("POST","http://45.89.225.221:9100/recognize", true);
            xhr.open("POST", this.recognize(), true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + ACCESS_TOKEN)
            xhr.send(fd);
        })
	},

    get_audio_url(meeting_id, audio_id) {
        // return 'https://45.89.225.221:9100/get_audio?audio_id=96f6456cd5940291ec5ec4ee8487baab&meeting_id=AAMkADAwZDQ0YWQ4LThkZTAtNDdkNS05NGQ3LTZjY2IyZTkzZGQ3YQBGAAAAAAAT3QJqyZxLRK+1QjUZ3XaEBwAxX+x2uMJnSaHsOWnoP8tYAAAAAAEMAAAxX+x2uMJnSaHsOWnoP8tYAACCjUWyAAA=
        return api_url + 'get_audio?audio_id=' + audio_id + '&meeting_id=' + meeting_id;
    },

    // get_minutes_file(meeting_id, filename) {
    get_minutes_file(meet) {

        const download = (data, filename = 'style_transfer.jpg', type = "image/jpeg") => {
            let urlCreator = window.URL || window.webkitURL;
          
            var file = new Blob([data], {type: type});
            if (window.navigator.msSaveOrOpenBlob) // IE10+
                window.navigator.msSaveOrOpenBlob(file, filename);
            else { // Others
                var a = document.createElement("a"),
                    url = urlCreator.createObjectURL(file);
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                    document.body.removeChild(a);
                    urlCreator.revokeObjectURL(url);  
                }, 0); 
            }
        }

        let d = meet.status.begin.format('L');
        let filename = 'Протокол встречи - ' + meet.meeting_core + ' (' + d + ')'
        let right_filename = filename.replace(/['"?& ]/g, ' ').replace(/  /g, ' ')

        // window.open('https://docs.google.com/forms/d/1OS_MOBRbYxfQWfvGx63H80Z73pvwf2EOGx_29rjwFdY/edit', '_blank').focus();

        // window.open( urlWithParams(api_url + 'get_minutes_file', {
        //     meeting_id: meet.id,
        //     filename: right_filename 
        // }), '_self').focus();

        // fetch( urlWithParams(api_url + 'get_minutes_file', {
        //     meeting_id: meet.id,
        //     filename: right_filename 
        // }).then( t => 
        //     t.blob().then( b => 
        //         utils.download(b, right_filename, 'text/plain') 
        //     )
        // )


        axios.get( api_url + 'get_minutes_file', {
            params: {
                meeting_id: meet.id,
                filename: right_filename 
            }
        }).then( t => {
            console.log(t, t.blob())
            t.blob().then( b => 
                download(b, right_filename, 'text/plain') 
            )
        })

    },

    create_mail(meeting_id) {

        // return Promise.resolve( {data: {status_demo:'ok'} } )
        
        if (demo_mode) {
            return new Promise((resolve, reject) => {
                resolve( 'ok' )
            })
        }
        return axios.post(api_url + 'create_mail', {}, {
            params: {
                meeting_id: meeting_id
            }
        }).then(res => {
            console.log('create_mail', res);
            return res.data.status
        })
    },

    auth(username = 'test@test.com', password = '11111111111111111111111111111111') {

        if (demo_mode) {
            return new Promise((resolve, reject) => {
                // resolve( demo_meetings )
                ACCESS_TOKEN = 'DEMO_MODE';

                user = {
                    // username: 'demouser',
                    username: username,
                    token: ACCESS_TOKEN,
                }

                resolve( { access_token: ACCESS_TOKEN } )
            })
        }

        return axios.post(api_url + 'auth', {
            username: username,
            password: password,
        }).then( e => {
            if (e.data.access_token) {
                ACCESS_TOKEN = e.data.access_token

                axios.defaults.headers.common = {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN
                }

                user = {
                    username: username,
                    token: ACCESS_TOKEN,
                }
            }

            console.log('AUTH', ACCESS_TOKEN)

            return e.data
        }, err => {
            console.log('AUTH-ERR', err)
            ACCESS_TOKEN = '';
            user = {};
            return Promise.reject(err)
        })
    },

    profile() {
        if (ACCESS_TOKEN) {
            return user
        } else {
            return null
        }
    }

}