// import logo from './logo.svg';
// import './App.css';

import React from 'react';

import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
  withRouter
} from "react-router-dom";

// import history from 'history/browser';
// import { createBrowserHistory } from "history";

import MeetingPage from './MeetingsPage';
import MeetingList from './MeetingList'; 
import LoginPage from './pages/LoginPage'; 
import WelcomePage from './pages/WelcomePage'; 
import CommandPage from './pages/CommandPage'; 

import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './antd.dark.css'
import { notification, Button } from 'antd';
import { WarningOutlined, PlusSquareOutlined, FireOutlined } from '@ant-design/icons';
 

// const history = createBrowserHistory();
// var global_err = null

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        list: [],
        // redirect: !api.profile() ? '/login' : '',
    }
    
  }
  componentDidMount() {
    // this.loadFileList()
    // this.connectToWS()

    api.test_auth().then(res=>{
      console.log('test_auth res', res)
      if (res == false) {
        this.redirect('/login')
      }
    }, err => {
      console.log('test_auth err', err)
      this.redirect('/login')
    })
  }
  
  // connectToWS = () => {
  //   let socket_messages = api.get_socket()
  
  //   socket_messages.on('new_meeting', (msg) => {
  //       console.log('[NEW_MEETING]', msg)
  //       this.loadFileList()
  //       notification.open({
  //         message: 'Новая встреча',
  //         description: msg.meeting_core + 
  //             ' (' + msg.meeting_type.type + "): " + 
  //             msg.meeting_points.join('; ') + ' / ' + 
  //             msg.meeting_agreed_datetime,
  //         icon: <PlusSquareOutlined style={{ color: 'green' }} />
  //       });
  //   });

  //   socket_messages.on('distortion', (msg) => {
  //       console.log('[DISTORTION]', msg)
  //       notification.open({
  //         message: 'КИ: ' + msg.distortions.join('; '),
  //         description: msg.fragment,
  //         icon: <FireOutlined style={{ color: 'yellow' }} />
  //       });
  //   });
  // }

  // loadFileList = () => {
  //   if (!api.profile()) { 
  //     this.redirect('/login');
  //     return
  //   } 
  //   api.get_meetings_list().then( res => {
  //     this.setState({list: res})
  //   }, err => {
  //     // console.log('loadFileList', err.code, err)
  //     // window.global_err = err
  //     if (err.request && err.request.status == 401 ) { // не авторизован
  //       // window.open('/login')
  //       //document.location.href = ('/login')
  //       this.redirect('/login')
  //     }
  //     notification.open({
  //       duration: 0,
  //       message: err.message,
  //       description: err.config?.url,
  //       onClick: () => {
  //           window.open('https://45.89.225.221:9100/get_meetings', '_blank').focus();
  //       },
  //       icon: <WarningOutlined style={{ color: 'red' }} />
  //     });
  //   })
  // }

  // static propTypes = {
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
  // };

  redirect = (path) => {
    // this.setState({ redirect: path });
    this.props.history.push( path );
  }


  render() {
    // const { match, location, history } = this.props;


    // <Router history={history}>
    return (<>

          
        
          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
            </ul>
          </nav> */}
          {/* { this.state.redirect ? <Redirect to={this.state.redirect} /> : '' } */}

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>

            {/* <Route path="/about">
              <About />
              <MeetingList list={this.state.list}/>
            </Route> */}

            {/* <Route path="/demo">
                <Button onClick={()=> { api.set_demo_mode(true); this.loadFileList(); }}>on DEMO mode</Button>
                <Button onClick={()=> { api.set_demo_mode(false)}}>off DEMO mode</Button>
                {api.get_demo_mode() ? 'TRUE' : 'FALSE'}
                <Link to="/">home</Link>
            </Route> */}

            

            

            <Route path="/login">
                  {/* const history = useHistory()
                  history.push('/meetings')  */}
                <LoginPage login={window.CONFIG.def_login} pass={window.CONFIG.def_pass} onLogin={ () => { 
                  // this.loadFileList(); 
                  this.redirect('/command'); 
                  // this.redirect('/meetings'); 
                } } />
                
                {/* <LoginPage onLogin={ () => console.log('onLogin') } /> */}
            </Route>
            {/* <Route path="/welcome">
                <WelcomePage nextpage="/meetings" />
            </Route> */}
            <Route path="/command">
                <CommandPage />
            </Route>
            {/* <Route path="/meetings/:id">
                
            </Route> */}
            {/* <Route path="/meetings/:id" component={<MeetingPage list={this.state.list} />}/> */}
            {/* <Route path="/meetings/:id">
                <MeetingPage list={this.state.list} />
            </Route>
            <Route path="/meetings">
                
                <MeetingList list={this.state.list} onLogout={() => this.redirect('/login') }/>
            </Route> */}

            <Route path="/">
                <Redirect to="/login" />
            </Route>

          </Switch>

          {/* <Link to="/">home</Link>
            <Link to="/login">login</Link>
            <Link to="/meetings"  as="button">meetings</Link>
            <button onClick={history.push('/')}>home</button>
            <button onClick={history.push('/login')}>login</button>
            <button onClick={history.push('/meetings')}>meetings</button> */}
            {/* <Link to="/" component={(props, ref) => (<Button {...props}>home</Button>)} /> */}

      {/* </Router> */}
      </>
    )
  }
}

const AppWithRouter = withRouter(App)


export default function WrapApp() {
  return (
    <Router>
      <AppWithRouter />
    </Router>
  )
};
