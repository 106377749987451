


import React, { useState } from 'react';

import {
    // Container, Row, Col, 
    // Cell, CellIcon, CellListItem,
    // Header,

    // HeaderRoot,
    // HeaderBack,
    // HeaderLogo,
    // HeaderTitleWrapper,
    // HeaderTitle,
    // HeaderSubtitle,
    // HeaderContent,

    Card,
    CardBody,
    CardContent,
    CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // TextBoxTitle,
    // Badge,
    // Tabs, TabItem,
    // Display2,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    // ActionButton,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    Underline,
    // Spinner
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';


import { Modal } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';



// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
//     useHistory
//   } from "react-router-dom";

import './rec.css'
import kilib from '../../dict/ki.js' 

const coverImgUrl = '/320_320_0.jpg';


//
// AboutKI 
//

export default function AboutKI(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMoreVisible, setIsMoreVisible] = useState(false);
    // const [width, setWidth] = useState('40%');
    const [width, setWidth] = useState('600px');

    const showMore = (e) => {
        width != '40%' ? setWidth('40%') : setWidth('70%')
        console.log(showMore, isMoreVisible, width)
        setIsMoreVisible(true);
        e.stopPropagation()        
    }
    const hideMore = (e) => {
        setIsMoreVisible(false);
        e.stopPropagation()        
    }
  
    const showModal = (e) => {
      setIsModalVisible(true);
      e.stopPropagation()
    };
  
    const handleOk = (e) => {
      setIsModalVisible(false);
      e.stopPropagation()
    };
  
    const handleCancel = (e) => {
      setIsModalVisible(false);
      e.stopPropagation()
    };
  
    let ki_type = props.type

    if (!ki_type || ki_type.length == 0) return ''

    let ki_item = kilib.find(x=>x.name == ki_type) || kilib[0]


    let contentRender = () => (

        <Card className={'ant-modal-content'} outlined={true} style={{height:'600px'}}>
            <CardBody>
                <CardMedia
                    // src={coverImgUrl}
                    placeholder={coverImgUrl}
                    // ratio={'1 / 1'}
                    style={{height:'600px'}}
                    />
                <CardContent cover={true}>
                    { props.ki ? (
                        // background: '#000000a0'
                        <Card style={{margin: '1rem 0'}}>
                            <CardBody>
                                <CardContent>
                                    {/* <Underline>Деталь</Underline> */}
                                    {/* <div>{props.ki?.KI_example}</div> */}
                                    {/* <Underline>Фрагмент</Underline> */}
                                    <div  dangerouslySetInnerHTML={{ 
                                        // __html: props.ki?.KI_real_text.replace(' '+props.ki?.KI_example+' ', ' <span class="ki_example">'+ props.ki?.KI_example + '</span> ') 
                                        __html: props.ki?.KI_real_text.replace(new RegExp('('+props.ki?.KI_example+')','i'), '<span class="ki_example">$1</span>') 
                                    }}/>
                                </CardContent>
                            </CardBody>
                        </Card>
                    ) : '' }
                    <TextBox>
                        <TextBoxBigTitle>Когнитивное искажение</TextBoxBigTitle>
                        <TextBoxBiggerTitle dangerouslySetInnerHTML={{ __html:ki_item.title }}></TextBoxBiggerTitle>
                        {/* <TextBoxSubTitle>{'item.meeting_points'}</TextBoxSubTitle> */}
                    </TextBox>
                    {/* <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                    </CardParagraph1> */}
                    {/* <p style={{opacity:0.5}} dangerouslySetInnerHTML={{ __html: ki_item.short.trim().replace(/\n/g, '<br/>') }}></p> */}
                    <p dangerouslySetInnerHTML={{ __html: ki_item.long.trim().replace(/\n/g, '<br/>') }}></p>


                    {/* <Modal 
                        title={'234'} 
                        visible={isMoreVisible} 
                        footer={[]} 
                        // modalRender={contentRender}
                        // onOk={handleOk} 
                        onCancel={hideMore} 
                        width={'75%'}
                        >
                        1223
                    </Modal> */}
                    {/* <br/> */}
                    {/* <Button size={'s'} onClick={showMore}>Подробнее</Button> */}
                </CardContent>
            </CardBody>
        </Card>
        
        
    )


    return (
      <span className="AboutKI-wrapper">
        <Button className="AboutKI-btn" type="primary" onClick={showModal} size={'s'}>
            <ThunderboltOutlined style={{marginRight: props.withtitle ? '7px' : '', fontSize:'20px' }} />
            { props.withtitle ? ki_type : '' }
        </Button>
        <Modal 
            title={ki_item.name} 
            visible={isModalVisible} 
            // footer={[]} 
            modalRender={() => contentRender()}
            // modalRender={(n) => <><Button size={'s'} onClick={showMore}>Подробнее</Button>{n}</>}
            onOk={handleOk} 
            onCancel={handleCancel} 
            // width={'50%'}
            width={width}
            
            >
                
        </Modal>
      </span>
    );
  };

