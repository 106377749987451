
import React, {useState} from 'react';
import {
    Container, Row, Col, 
    // Cell, CellIcon,
    Header,
    // Card,
    // CardBody,
    // CardContent,
    // CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    // TextBoxBigTitle,
    // TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    TextField,
    Button,
    Tabs, TabItem,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    ActionButton,
    Spinner,
// Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import  {
    WarningOutlined
 } from '@ant-design/icons';

 import {
    IconApps,
    IconCalendar,
    IconEye,
    IconSleep,
    IconPersone,
    IconLock
 } from '@sberdevices/plasma-icons';

import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
    useHistory
  } from "react-router-dom";
  

// import MeetCards from './cards.js'
// import Calendar from './calendar.js'

// import { Progress } from 'antd';

import { Row as Row2, Col as Col2, notification } from 'antd';

import api from './../api/api'


function LoginForm({login = 'test@test.com', pass = '11111111111111111111111111111111', onLogin}) {
    const [valLogin, setLogin] = useState(login);
    const [valPass, setPass] = useState(pass);
    const [is_loading, setLoading] = useState(false);
    const [is_error, setError] = useState(false);
    const [showPass, setShowPass] = useState(false);
    
    return (<>
        <TextField
                value={valLogin}
                label={'Логин'}
                disabled={false}
                // status={'success'}
                contentLeft={<IconPersone color="inherit" size="s" />}
                onChange={(v) => { setLogin(v.target.value); setError(false) }}
                // onFocus={action('onFocus')}
                // onBlur={action('onBlur')}
            />
        <br/>
        <TextField
                value={valPass}
                label={'Пароль'}
                type={showPass ? 'text' : 'password'}
                helperText={ is_error ? 'Проверьте логин и пароль' : ''}
                disabled={false}
                // status={'success'}
                status={'error'}
                contentLeft={<IconLock color="inherit" size="s" />}
                contentRight={
                    <ActionButton view="clear" onClick={()=>setShowPass(!showPass)}>
                        { showPass ? 
                            <IconEye color="#ffffff30" size="s" />
                            :
                            <IconEye color="#ffffff8f" size="s" />
                        }
                    </ActionButton>
                }
                onChange={(v) => { setPass(v.target.value); setError(false) }}
                // onFocus={action('onFocus')}
                // onBlur={action('onBlur')}
            />
        <br/>
        <Button view="primary" stretch={true} onClick={() => { 
            setError(false)
            setLoading(true)
            api.auth(valLogin, valPass).then(
                (res) => { 
                    setLoading(false)
                    onLogin(); 
                }, 
                (err) => { 
                    console.log(err, err.request)

                    if (err.request && err.request.status == 401 ) { // не авторизован
                        // window.open('/login')
                        //document.location.href = ('/login')
                        // this.redirect('/login')
                    }
                    notification.open({
                        duration: 0,
                        message: err.message,
                        description: err.config?.url,
                        onClick: () => {
                            // window.open('https://45.89.225.221:9100/get_meetings', '_blank').focus();
                            window.open(api.get_test_url(), '_blank').focus();
                        },
                        icon: <WarningOutlined style={{ color: 'red' }} />
                    });

                    setLoading(false)
                    setError(true) 
                }
            )
            
        }}>{ is_loading ? <Spinner size={20} color={'white'}/> : 'Войти' }</Button>
    </>)
}




function LoginList({onLogin, onElse}) {
    const [valLogin, setLogin] = useState('');
    // const [valPass, setPass] = useState(pass);
    const [is_loading, setLoading] = useState(false);
    // const [is_error, setError] = useState(false);
    // const [showPass, setShowPass] = useState(false);

    const auth = (login, pass) => { 
        // setError(false)
        setLogin(login)
        setLoading(true)
        api.auth(login, pass).then(
            (res) => { 
                setLoading(false)
                onLogin(); 
            }, 
            (err) => { 
                console.log(err, err.request)

                if (err.request && err.request.status == 401 ) { // не авторизован
                    // window.open('/login')
                    //document.location.href = ('/login')
                    // this.redirect('/login')
                }
                notification.open({
                    duration: 0,
                    message: err.message,
                    description: err.config?.url,
                    onClick: () => {
                        // window.open('https://45.89.225.221:9100/get_meetings', '_blank').focus();
                        window.open(api.get_test_url(), '_blank').focus();
                    },
                    icon: <WarningOutlined style={{ color: 'red' }} />
                });

                setLoading(false)
                // setError(true) 
            }
        )
        
    }
    
    return (<>
        <h3 style={{textAlign:'center', marginTop:0}}>Войти как</h3>
        {window.CONFIG.def_user_list.map((u,i) => 
            <Button view="secondary" key={i} stretch={true} style={{marginBottom:'10px'}} onClick={() => {
                auth(u.login, u.pass)
            }}>{ is_loading && valLogin === u.login ? <Spinner size={20} color={'white'}/> : u.login }</Button>
        )}
        <Button view="clear" stretch={true} style={{marginBottom:'10px', fontWeight:'normal'}} onClick={() => {
                onElse();
            }}>Другой пользователь</Button>
    </>)
}



export default function LoginPage({login, pass, onLogin}) {

    const [ showList, setShowList ] = useState( true );
    const [ is_logined, set_is_logined ] = useState( false );

    const sayHello = () => {
        set_is_logined(true)
        setTimeout(() => {
            onLogin()
        }, 2500)
    }

    return (
        <Container className={'main_center_wrapper ' + (!is_logined ? '' : 'is_logined')}>
            
                <div></div>
                <div></div>


                <img className="dmm-logo" src={"/logo_sdm.png"} />

                <div className="login-form-wrapper">
                    {/* <LoginForm onLogin={ () => props.onLogin() } /> */}
                    { !is_logined ? 
                        (showList ?
                            <LoginList onLogin={ () => sayHello() } onElse={()=>{ setShowList(false)}} />
                            :
                            <LoginForm login={login} pass={pass} onLogin={ () => sayHello() } />
                        )

                        :
                        <div className="welcomeText" style={{textAlign:'center'}}>
                            <h1 style={{lineHeight:1.1}}>Добрый&nbsp;день, <br/>{api.profile().username.split(' ')[0]}!</h1>
                            <br />
                            
                            <big>
                                Добро пожаловать <br/>в мир DM
                            </big>
                        </div>
                    }
                </div>

                <div></div>
                <div></div>

                <div className="footer-sberai">
                    <p>
                        <small>powered by</small><img src="/sberai_logo.svg" />
                    </p>
                </div>

                <style>
                    {`
                        .main_center_wrapper{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100vh;
                            align-items: center;
                        }
                        .dmm-logo {
                            width:170px;
                            margin: 30px auto 30px;
                            display: block;
                        }
                        .login-form-wrapper {
                            max-width: 400px;
                            width: 100%;
                            margin: 0 auto;
                            transition: all 1s ease-out;
                        }
                        .login-form-wrapper {
                            height:220px;
                        }
                        .is_logined .login-form-wrapper {
                            height:150px;
                        }
                        .welcomeText {
                            opacity: 0;
                            transition: all 1s ease-out;
                        }
                        .is_logined .welcomeText {
                            opacity: 1;
                        }
                        .footer-sberai img {
                            vertical-align: middle;
                            width: 90px;
                            margin: 10px;
                        }
                    `}
                </style>

        </Container>
    )
}