
import moment from 'moment';



export let demo_stenography = [
    // {
    //     "id": "6ded8fdb62fc4b009fda636f2f5b67f9",
    //     "text": "да, да, да я говорю все понял логично хорошо ещё один",
    //     "is_minute": 0,
    //     "KI": []
    //   },
    //   {
    //     "id": "aa0eca64005b9a4b251a5fdb85b14006",
    //     "text": "момент сейчас, сейчас внедрить Интер Лив в теорию помнишь такая задача была она стояла у нас с тобой. Я помню, да, она стояла у нас",
    //     "is_minute": 0,
    //     "KI": []
    //   },
      {
        "id": "af35b903372daad6403453d31b34e7e0",
        "text": "Я помню, да, она стояла у нас,",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "f72fd687af0e4f65a517936bbe939def",
        "text": "но это нужно ещё обдумать.",
        "is_minute": 0,
        "KI": [
          {
            "type": "Недооценка бездействия",
            "KI_example": "нужно ещё обдумать",
            "KI_real_text": "...да, она стояла у нас с тобой, но Настя начала ее уже делать. Я замечала несколько моментов в ее тексте но это нужно ещё обдумать. Особенно тебе нужно перечитать. Этот текст ее и проверить точно ли там..."
          }
        ]
      },
      {
        "id": "e02803faea2db3822618f92c28b7bcsdfa5",
        "text": "В следующем релизе нужно исправить все баги,",
        "is_minute": true,
        "KI": []
      },
      {
        "id": "e02803faea2db3822618f92c28b7bca5",
        "text": "Особенно тебе нужно перечитать.",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "0f8f0fe8d27824aa6f898e7a8ac5d24c",
        "text": "Этот текст всё и проверить ",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "dca615df076420ddea5c237ec262a1e3",
        "text": "точно ли там достаточно для нас",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "8d6e92cefdb7ba3c688dfd905d784f7e",
        "text": "раз УП употребляется в нашем интервью.",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae26a98a301297617da754",
        "text": "Сделаем к понедельнику, спасибо большое.",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae236a98a3012976617da754",
        "text": "Ну, что он может начать обсуждать, ",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae26a98a30129766417da754",
        "text": "как будем адаптировать теорию у тебя открыт",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae26a98a3012976617da754",
        "text": "говорит лайн, нет, сейчас открою угу,",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae26a98a30129076617da754",
        "text": "открыл, а ну, супер.",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae26a98a30129766187da754",
        "text": "Переходи сразу к третьей страничке хотя",
        "is_minute": 0,
        "KI": []
      },
      {
        "id": "a873674d22ae26a98a301297661799da754",
        "text": "стол у нас же начинается",
        "is_minute": 0,
        "KI": []
      }
]


// let list = [{
//     "id": "2_ABaOnudnzC3TYIdIMM8JbRZ5ZvqrpAHh2i8uW4zNFdfjRvQ4-ewG05w21LCYI4vDlXAVCU0",
//     "meeting_type": {
//         "type": "Поиск решения",
//         "code": 2
//     },
//     "meeting_core": "Работа с курсом по Психотипам",
//     "meeting_points": ["Добавить в образ результата:\n- надо научить директоров учить других\n- учить они будут в онлайне\n- научить их коммуницировать на основе психотипо", "Написать образовательные цели", "Поработать над программой - проапгрейдить предыдущую"],
//     meeting_participants: [
    //     {
    //         description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
    //         email: "MIStepnov@sberbank.ru",
    //         status: "0"
    //     },
    //     {
    //         description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
    //         email: "Vasichkin.E.Ol@sberbank.ru",
    //         status: "0"
    //     }
    // ],
//     "meeting_additional_participants": ["Екатерина Маслякова, Лаборатория нейронаук"],
//     "meeting_wanted_window": {
//         "before_date": "2021-02-25",
//         "before_time": "19:00",
//         "duration": "1,5 часа"
//     },
//     "meeting_agreed_datetime": "2021-02-24 19:00",
//     "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
//     "meeting_prepare": null
// }, {
//     "id": "2_ABaOnudZwyMd105xzBYGKV7Q3BUVJd5icVtdHX5CcbFhwGUv5EGBXY4t8XoKLSIrLjvx7qk",
//     "meeting_type": {
//         "type": "Новые вводные",
//         "code": 4
//     },
//     "meeting_core": "Новые вводные по курсу для рВСП",
//     "meeting_points": ["Расскажу о неожиданных новостях после встречи с заказчиком"],
//     meeting_participants: [
    //     {
    //         description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
    //         email: "MIStepnov@sberbank.ru",
    //         status: "0"
    //     },
    //     {
    //         description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
    //         email: "Vasichkin.E.Ol@sberbank.ru",
    //         status: "0"
    //     }
    // ],
//     "meeting_additional_participants": [],
//     "meeting_wanted_window": {
//         "before_date": "2021-03-02",
//         "before_time": "15:00",
//         "duration": "30 мин"
//     },
//     "meeting_agreed_datetime": "2021-02-23 15:00",
//     "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
//     "meeting_prepare": "вспомнить предыдущую программу этого курса"
// }, {
//     "id": "2_ABaOnudZwyMd105xzBYGKV7Q3BUVJd5icVtdHX5CcbFhwGUv5EGBXY4t8XoKLSIrLjvx7qk",
//     "meeting_type": {
//         "type": "Новые вводные",
//         "code": 4
//     },
//     "meeting_core": "Новые вводные по курсу для рВСП",
//     "meeting_points": ["Расскажу о неожиданных новостях после встречи с заказчиком"],
//     meeting_participants: [
    //     {
    //         description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
    //         email: "MIStepnov@sberbank.ru",
    //         status: "0"
    //     },
    //     {
    //         description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
    //         email: "Vasichkin.E.Ol@sberbank.ru",
    //         status: "0"
    //     }
    // ],
//     "meeting_additional_participants": [],
//     "meeting_wanted_window": {
//         "before_date": "2021-03-02",
//         "before_time": "15:00",
//         "duration": "30 мин"
//     },
//     "meeting_agreed_datetime": "2021-02-23 15:00",
//     "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
//     "meeting_prepare": "вспомнить предыдущую программу этого курса"
// }, {
//     "id": "2_ABaOnudZwyMd105xzBYGKV7Q3BUVJd5icVtdHX5CcbFhwGUv5EGBXY4t8XoKLSIrLjvx7qk",
//     "meeting_type": {
//         "type": "Новые вводные",
//         "code": 4
//     },
//     "meeting_core": "Новые вводные по курсу для рВСП",
//     "meeting_points": ["Расскажу о неожиданных новостях после встречи с заказчиком"],
//     meeting_participants: [
    //     {
    //         description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
    //         email: "MIStepnov@sberbank.ru",
    //         status: "0"
    //     },
    //     {
    //         description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
    //         email: "Vasichkin.E.Ol@sberbank.ru",
    //         status: "0"
    //     }
    // ],
//     "meeting_additional_participants": [],
//     "meeting_wanted_window": {
//         "before_date": "2021-03-02",
//         "before_time": "15:00",
//         "duration": "30 мин"
//     },
//     "meeting_agreed_datetime": "2021-02-23 15:00",
//     "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
//     "meeting_prepare": "вспомнить предыдущую программу этого курса"
// }];



// list = [{"id": "2_ABaOnudnzC3TYIdIMM8JbRZ5ZvqrpAHh2i8uW4zNFdfjRvQ4-ewG05w21LCYI4vDlXAVCU0", "meeting_type": {"type": "Поиск решения", "code": 2}, "meeting_core": "Работа с курсом по Психотипам", "meeting_points": ["Добавить в образ результата:\n- надо научить директоров учить других\n- учить они будут в онлайне\n- научить их коммуницировать на основе психотипо", "Написать образовательные цели", "Поработать над программой - проапгрейдить предыдущую"], meeting_participants: [
    //     {
    //         description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
    //         email: "MIStepnov@sberbank.ru",
    //         status: "0"
    //     },
    //     {
    //         description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
    //         email: "Vasichkin.E.Ol@sberbank.ru",
    //         status: "0"
    //     }
    // ],



export let demo_meetings = [{
    "id": "38620225fde24ee2859da4eb9226562b",
    "source": "outlook",
    "meeting_type": {
        "type": "Синхронизация",
        "code": 3
    },
    "meeting_core": "Планируем исследование Online vs Offline",
    "meeting_points": [{
        "description": "Рассказываем, откуда брали данные",
        "status": "done"
    }, {
        "description": "Выбираем и распределяем следующие шаги",
        "status": "done"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-03-16 09:00:00+03:00",
        "before_time": "2021-03-16 09:00:00+03:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-03-16 09:30:00+03:00",
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": [{
        "id": "21cdd9420fc8bac96c2815c35616aa4f",
        "text": "Давно, и оба. Кстати, надо поменять немного анимировать. Если вы не знаете, как вырезать хромоте. Да. Винчи. Пишите в комментариях сделаю отдельный скринкаст да Винчи лучший пакет",
        "is_minute": true,
        "KI": [{
            "type": "Отклонение в сторону статуса кво",
            "KI_example": "сделаю",
            "KI_real_text": "..., надо поменять немного анимировать. Если вы не знаете, как вырезать хромоте. Да. Винчи. Пишите в комментариях сделаю отдельный скринкаст..."
        }, {
            "type": "Избегание неопределенности",
            "KI_example": "знаете,",
            "KI_real_text": "...надо поменять немного анимировать. Если вы не знаете, как вырезать хромоте. Да. Винчи. Пишите в комментариях сделаю отдельный скринкаст ..."
        }]
    }, {
        "id": "72b658be9287da326a240468a0dc9796",
        "text": "Сегодня или ускорять куски по времени и следить, чтобы они попадали в текст. Ну, то есть, чтобы я рассказывал о том, что происходит на экране и все остается только подложить",
        "is_minute": false,
        "KI": []
    }, {
        "id": "96f6456cd5940291ec5ec4ee8487baab",
        "text": "Описанного заранее скринкаст задача это тоже непростая, потому что конечно, записанный скринкаст гораздо длиннее, чем записанная подводка. Ну, написанные вами текст",
        "is_minute": false,
        "KI": [{
            "type": "Эффект ложного консенсуса",
            "KI_example": "чем",
            "KI_real_text": "...инкаст задача это тоже непростая, потому что конечно, записанный скринкаст гораздо длиннее, чем записанная подводка. Н..."
        }]
    }, {
        "id": "63a2eb56f0c8b94fc6703ae6c68f0efa",
        "text": "Ты мне бы хотелось, чтобы таких каналов становилось больше. А еще мне кажется, что я все таки делают качественные скрин. Касты. Ну, по крайней мере, судя по вашим комментариям. Поэтому, если вы",
        "is_minute": true,
        "KI": [{
            "type": "Избегание неопределенности",
            "KI_example": "Ну,",
            "KI_real_text": "...обы таких каналов становилось больше. А еще мне кажется, что я все таки делают качественные скрин. Касты. Ну, по крайней мере, судя по вашим комментари..."
        }, {
            "type": "Предвзятость подтверждения",
            "KI_example": "делают",
            "KI_real_text": "...обы таких каналов становилось больше. А еще мне кажется, что я все таки делают качественные скрин. Касты. Ну, по крайней мере, судя по вашим коммен..."
        }]
    }, {
        "id": "8c159bfbe07e4ce9e5c6123efc2c2dc8",
        "text": "На фоне деградации телевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того, что мы не вкладываем день",
        "is_minute": false,
        "KI": [{
            "type": "Избегание неопределенности",
            "KI_example": "от",
            "KI_real_text": "...визора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того, ч..."
        }]
    }, {
        "id": "e047af3eca5182263cb302b8cdba2802",
        "text": "И собственно стендап готов дальше сам скринкаст. Опять же тексте опишу заранее прохожу весь процесс, о котором рассказываю записываю параллельно собственно, со",
        "is_minute": true,
        "KI": [{
            "type": "Недооценка бездействия",
            "KI_example": "котором",
            "KI_real_text": "...готов дальше сам скринкаст. Опять же тексте опишу заранее прохожу весь процесс, о котором рассказываю записываю парал..."
        }, {
            "type": "Эффект сверхуверенности",
            "KI_example": "прохожу",
            "KI_real_text": "... готов дальше сам скринкаст. Опять же тексте опишу заранее прохожу весь процесс, о котором рассказываю записываю парал..."
        }]
    }, {
        "id": "5e6cbe1d28b3f4634bb348cfd18a7281",
        "text": "Тобой приборов также на первых порах можно использовать строительные прожектора, они дешевые но оставить их лучше. Конечно, через зонтик ещё вам понадобится хромакей кусок",
        "is_minute": true,
        "KI": [{
            "type": "Отклонение в сторону статуса кво",
            "KI_example": "оставить",
            "KI_real_text": "...рвых порах можно использовать строительные прожектора, они дешевые но оставить их лучше. Конечно, через зонтик ещё вам пона..."
        }]
    }, {
        "id": "1a4febf36b38a1503e7aee7b03f2c714",
        "text": "А что у нас отличный российский. Сегмент есть что посмотреть, да и вообще у нас все хорошо, все любите друг друга смотрите, YouTube. Подписывайтесь, на наш канал и оставляйте вам",
        "is_minute": false,
        "KI": [{
            "type": "Эффект ложного консенсуса",
            "KI_example": "А",
            "KI_real_text": "...сийский. Сегмент есть что посмотреть, да и вообще у нас все хорошо, все любите друг друга смотрите, YouTube. Подписывайтесь, на ..."
        }, {
            "type": "Искажение коллективной ответственности",
            "KI_example": "канал",
            "KI_real_text": "...ссийский. Сегмент есть что посмотреть, да и вообще у нас все хорошо, все любите друг друга смотрите, YouTube. Подписывайтесь, на наш к..."
        }]
    }, {
        "id": "30c817ffca0d377ebf6a6983c259462d",
        "text": "Просто в комментариях к этому видео и пишите нам о том, что ещё хотели бы узнать в теплице социальных технологий.",
        "is_minute": false,
        "KI": [{
            "type": "Отклонение в сторону статуса кво",
            "KI_example": "в",
            "KI_real_text": "... этому видео и пишите нам о том, что ещё хотели бы узнать в тепли..."
        }]
    }, {
        "id": "8edfe5b1d50c653144ad5a3b7a556a95",
        "text": "В очередь голос над голосом. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остается смонтировать куски за",
        "is_minute": true,
        "KI": [{
            "type": "Избегание неопределенности",
            "KI_example": "сценической",
            "KI_real_text": "...голосом. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остается..."
        }]
    }, {
        "id": "4ef4d4c74e7ccd1762050971ef933cdc",
        "text": "Ну, или в крайнем случае, просто петличку и подключать ее к камере радио петля тут не нужна достаточно иметь проводную. Далее я пишу текст, если комод",
        "is_minute": false,
        "KI": []
    }, {
        "id": "06ff9bcf760f8821b744ff448ba894a4",
        "text": "",
        "is_minute": true,
        "KI": [{
            "type": "Ошибка планирования",
            "KI_example": "",
            "KI_real_text": "......"
        }]
    }]
}, {
    "id": "ed557e69f69937257277a3d81a375d2d",
    "source": "outlook",
    "meeting_type": {
        "type": "Новые вводные",
        "code": 2
    },
    "meeting_core": "Тестирование программ курса",
    "meeting_points": [{
        "description": "Проверяем ответы на три ключевых вопроса",
        "status": "done"
    }, {
        "description": "Определить роли",
        "status": "done"
    }, {
        "description": "Выделить направления",
        "status": "undone"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-06-20 09:00:00+03:00",
        "before_time": "2021-06-20 09:00:00+03:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-06-20 09:30:00+03:00",
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": [{
        "id": "9ce44341acc11f061515fec83c03aa1b",
        "text": "Музыку и скринкаст готов мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на русском, то что встреча",
        "is_minute": true,
        "KI": [{
            "type": "Предвзятость подтверждения",
            "KI_example": "просто",
            "KI_real_text": "... мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на..."
        }]
    }, {
        "id": "63a2eb56f0c8b94fc6703ae6c68f0efa",
        "text": "Ты мне бы хотелось, чтобы таких каналов становилось больше. А еще мне кажется, что я все таки делают качественные скрин. Касты. Ну, по крайней мере, судя по вашим комментариям. Поэтому, если вы",
        "is_minute": true,
        "KI": []
    }, {
        "id": "3c52eceee5b98023d7fc04fd67b52953",
        "text": "Я хочу рассказать, как я делаю свои видео. Вдруг кто-то подхватят, потому что те скринкаст и который я периодически смотрю на русском языке к сожалению, не отличается высоким",
        "is_minute": false,
        "KI": [{
            "type": "Эффект ложного консенсуса",
            "KI_example": "который",
            "KI_real_text": "...елаю свои видео. Вдруг кто-то подхватят, потому что те скринкаст и который я периодически смотрю на русском языке к сожалению,..."
        }, {
            "type": "Отклонение в сторону статуса кво",
            "KI_example": "не",
            "KI_real_text": "...елаю свои видео. Вдруг кто-то подхватят, потому что те скринкаст и который я периодически смотрю на русском языке к сожалению, ..."
        }]
    }, {
        "id": "72b658be9287da326a240468a0dc9796",
        "text": "Сегодня или ускорять куски по времени и следить, чтобы они попадали в текст. Ну, то есть, чтобы я рассказывал о том, что происходит на экране и все остается только подложить",
        "is_minute": true,
        "KI": [{
            "type": "Предвзятость подтверждения",
            "KI_example": "есть,",
            "KI_real_text": "... куски по времени и следить, чтобы они попадали в текст. Ну, то есть, чтобы я рассказывал о том, что происходит на экране и все оста..."
        }]
    }, {
        "id": "747b19db931e00cf24647b9107a881a6",
        "text": "Качества и так в связи с тем, что я занимаюсь видео продакшном. Это мой хлеб у меня естественно есть оборудование. Собственно сделать качественное видео. Без хорошего оборудование. Вы не смог.",
        "is_minute": false,
        "KI": []
    }, {
        "id": "e047af3eca5182263cb302b8cdba2802",
        "text": "И собственно стендап готов дальше сам скринкаст. Опять же тексте опишу заранее прохожу весь процесс, о котором рассказываю записываю параллельно собственно, со",
        "is_minute": true,
        "KI": []
    }, {
        "id": "08687f158f02c4ddc4a173d837126007",
        "text": "Всем привет с вами Вова Ломов. Эти в лица социальных технологий, видео в сети, особенно",
        "is_minute": true,
        "KI": [{
            "type": "Избегание неопределенности",
            "KI_example": "технологий,",
            "KI_real_text": "...ова Ломов. Эти в лица социальных технологий..."
        }]
    }, {
        "id": "33d09662cff2df54bc4c9d80dc6b88da",
        "text": "Вот, например, длина всех записанных кусков одного из моих скринкаст, в прогул оптимально а вот длина текста, то есть дальше мне приходится вырезать и выкидывать, все ли",
        "is_minute": true,
        "KI": []
    }, {
        "id": "4ef4d4c74e7ccd1762050971ef933cdc",
        "text": "Ну, или в крайнем случае, просто петличку и подключать ее к камере радио петля тут не нужна достаточно иметь проводную. Далее я пишу текст, если комод",
        "is_minute": true,
        "KI": []
    }, {
        "id": "21cdd9420fc8bac96c2815c35616aa4f",
        "text": "Давно, и оба. Кстати, надо поменять немного анимировать. Если вы не знаете, как вырезать хромоте. Да. Винчи. Пишите в комментариях сделаю отдельный скринкаст да Винчи лучший пакет",
        "is_minute": false,
        "KI": []
    }, {
        "id": "6d00f09b76223a45416caa49b7b2bff5",
        "text": "Он скринкаст скринкаст. Я пишу на бесплатную программу под названием, а УК можете использовать любую другую их много, но это вполне сносное, пишет девятнадцать, двадцать на тысячу",
        "is_minute": true,
        "KI": [{
            "type": "Недооценка бездействия",
            "KI_example": "вполне",
            "KI_real_text": "...ст. Я пишу на бесплатную программу под названием, а УК можете использовать любую другую их много, но это вполне сносное, пишет девятна..."
        }]
    }, {
        "id": "8edfe5b1d50c653144ad5a3b7a556a95",
        "text": "В очередь голос над голосом. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остается смонтировать куски за",
        "is_minute": true,
        "KI": [{
            "type": "Эффект ложного консенсуса",
            "KI_example": "на",
            "KI_real_text": "...м. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остается с..."
        }, {
            "type": "Предвзятость подтверждения",
            "KI_example": "голос",
            "KI_real_text": "...лосом. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остаетс..."
        }]
    }, {
        "id": "8c159bfbe07e4ce9e5c6123efc2c2dc8",
        "text": "На фоне деградации телевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того, что мы не вкладываем день",
        "is_minute": false,
        "KI": [{
            "type": "Недооценка бездействия",
            "KI_example": "первую",
            "KI_real_text": "...елевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того,..."
        }, {
            "type": "Избегание неопределенности",
            "KI_example": "не",
            "KI_real_text": "...елевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того, ч..."
        }]
    }]
}, {
    "id": "ed557e69f69987255277a3d81a375d2d",
    "source": "outlook",
    "meeting_type": {
        "type": "Новые вводные",
        "code": 2
    },
    "meeting_core": "Разработка программ курса по типам",
    "meeting_points": [{
        "description": "Готовим ответы на три ключевых вопроса (будем размещать их на доске в виде стикеров)",
        "status": "done"
    }, {
        "description": "Определить роли",
        "status": "done"
    }, {
        "description": "Выделить направления",
        "status": "undone"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-06-17 09:00:00+03:00",
        "before_time": "2021-06-17 09:00:00+03:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-06-17 09:30:00+03:00",
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": [{
        "id": "9ce44341acc11f061515fec83c03aa1b",
        "text": "Музыку и скринкаст готов мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на русском, то что встреча",
        "is_minute": true,
        "KI": [{
            "type": "Предвзятость подтверждения",
            "KI_example": "просто",
            "KI_real_text": "... мне кажется, что это очень просто странно при этом, что мы часто встречаем ютюб на..."
        }]
    }, {
        "id": "63a2eb56f0c8b94fc6703ae6c68f0efa",
        "text": "Ты мне бы хотелось, чтобы таких каналов становилось больше. А еще мне кажется, что я все таки делают качественные скрин. Касты. Ну, по крайней мере, судя по вашим комментариям. Поэтому, если вы",
        "is_minute": true,
        "KI": []
    }, {
        "id": "3c52eceee5b98023d7fc04fd67b52953",
        "text": "Я хочу рассказать, как я делаю свои видео. Вдруг кто-то подхватят, потому что те скринкаст и который я периодически смотрю на русском языке к сожалению, не отличается высоким",
        "is_minute": false,
        "KI": [{
            "type": "Эффект ложного консенсуса",
            "KI_example": "который",
            "KI_real_text": "...елаю свои видео. Вдруг кто-то подхватят, потому что те скринкаст и который я периодически смотрю на русском языке к сожалению,..."
        }, {
            "type": "Отклонение в сторону статуса кво",
            "KI_example": "не",
            "KI_real_text": "...елаю свои видео. Вдруг кто-то подхватят, потому что те скринкаст и который я периодически смотрю на русском языке к сожалению, ..."
        }]
    }, {
        "id": "72b658be9287da326a240468a0dc9796",
        "text": "Сегодня или ускорять куски по времени и следить, чтобы они попадали в текст. Ну, то есть, чтобы я рассказывал о том, что происходит на экране и все остается только подложить",
        "is_minute": true,
        "KI": [{
            "type": "Предвзятость подтверждения",
            "KI_example": "есть,",
            "KI_real_text": "... куски по времени и следить, чтобы они попадали в текст. Ну, то есть, чтобы я рассказывал о том, что происходит на экране и все оста..."
        }]
    }, {
        "id": "747b19db931e00cf24647b9107a881a6",
        "text": "Качества и так в связи с тем, что я занимаюсь видео продакшном. Это мой хлеб у меня естественно есть оборудование. Собственно сделать качественное видео. Без хорошего оборудование. Вы не смог.",
        "is_minute": false,
        "KI": []
    }, {
        "id": "e047af3eca5182263cb302b8cdba2802",
        "text": "И собственно стендап готов дальше сам скринкаст. Опять же тексте опишу заранее прохожу весь процесс, о котором рассказываю записываю параллельно собственно, со",
        "is_minute": true,
        "KI": []
    }, {
        "id": "08687f158f02c4ddc4a173d837126007",
        "text": "Всем привет с вами Вова Ломов. Эти в лица социальных технологий, видео в сети, особенно",
        "is_minute": true,
        "KI": [{
            "type": "Избегание неопределенности",
            "KI_example": "технологий,",
            "KI_real_text": "...ова Ломов. Эти в лица социальных технологий..."
        }]
    }, {
        "id": "33d09662cff2df54bc4c9d80dc6b88da",
        "text": "Вот, например, длина всех записанных кусков одного из моих скринкаст, в прогул оптимально а вот длина текста, то есть дальше мне приходится вырезать и выкидывать, все ли",
        "is_minute": true,
        "KI": []
    }, {
        "id": "4ef4d4c74e7ccd1762050971ef933cdc",
        "text": "Ну, или в крайнем случае, просто петличку и подключать ее к камере радио петля тут не нужна достаточно иметь проводную. Далее я пишу текст, если комод",
        "is_minute": true,
        "KI": []
    }, {
        "id": "21cdd9420fc8bac96c2815c35616aa4f",
        "text": "Давно, и оба. Кстати, надо поменять немного анимировать. Если вы не знаете, как вырезать хромоте. Да. Винчи. Пишите в комментариях сделаю отдельный скринкаст да Винчи лучший пакет",
        "is_minute": false,
        "KI": []
    }, {
        "id": "6d00f09b76223a45416caa49b7b2bff5",
        "text": "Он скринкаст скринкаст. Я пишу на бесплатную программу под названием, а УК можете использовать любую другую их много, но это вполне сносное, пишет девятнадцать, двадцать на тысячу",
        "is_minute": true,
        "KI": [{
            "type": "Недооценка бездействия",
            "KI_example": "вполне",
            "KI_real_text": "...ст. Я пишу на бесплатную программу под названием, а УК можете использовать любую другую их много, но это вполне сносное, пишет девятна..."
        }]
    }, {
        "id": "8edfe5b1d50c653144ad5a3b7a556a95",
        "text": "В очередь голос над голосом. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остается смонтировать куски за",
        "is_minute": true,
        "KI": [{
            "type": "Эффект ложного консенсуса",
            "KI_example": "на",
            "KI_real_text": "...м. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остается с..."
        }, {
            "type": "Предвзятость подтверждения",
            "KI_example": "голос",
            "KI_real_text": "...лосом. Точнее, на сценической речи интонированием тоже надо бы кстати поработать и все. Когда звук готов остаетс..."
        }]
    }, {
        "id": "8c159bfbe07e4ce9e5c6123efc2c2dc8",
        "text": "На фоне деградации телевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того, что мы не вкладываем день",
        "is_minute": false,
        "KI": [{
            "type": "Недооценка бездействия",
            "KI_example": "первую",
            "KI_real_text": "...елевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того,..."
        }, {
            "type": "Избегание неопределенности",
            "KI_example": "не",
            "KI_real_text": "...елевизора бьет все рекорды по просмотрам. Наш канал тут не отличается сверху высокими показателями. Но это в первую очередь от того, ч..."
        }]
    }]
}, {
    "id": "9f4bea766abaaea3d0cb0a90a8d8e156",
    "source": "outlook",
    "meeting_type": {
        "type": "Планирование",
        "code": 4
    },
    "meeting_core": "Пишем тренажер «Токсичность»",
    "meeting_points": [{
        "description": "Все дают ОС",
        "status": "undone"
    }, {
        "description": "Готовим ответы на три ключевых вопроса (будем размещать их на доске в виде стикеров)",
        "status": "undone"
    }, {
        "description": "Работаем с содержанием (теорией) по курсу",
        "status": "undone"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-04-01 09:00:00+03:00",
        "before_time": "2021-04-01 09:00:00+03:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-04-01 09:30:00+03:00",
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": []
}, {
    "id": "c33e766f663ca401ae5c126f41f56317",
    "source": "outlook",
    "meeting_type": {
        "type": "Новые вводные",
        "code": 2
    },
    "meeting_core": "Пишем тренажер «Токсичность»",
    "meeting_points": [{
        "description": "как можно использовать обнаруженное в текстах в планировании нашего исследования",
        "status": "undone"
    }, {
        "description": "Получаем ОС",
        "status": "undone"
    }, {
        "description": "Решаем, что будет входить в ТЗ и утверждаем его структуру",
        "status": "undone"
    }, {
        "description": "Как они могут закрыть определенные потребности в проектах Лаборатории",
        "status": "undone"
    }, {
        "description": "Рассказываем, откуда брали данные",
        "status": "undone"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-04-16 09:00:00+03:00",
        "before_time": "2021-04-16 09:00:00+03:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-04-16 09:30:00+03:00",
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": []
},
{
    "id": "c33e766f663ca401ae5cd126f41f56317",
    "source": "outlook",
    "meeting_type": {
        "type": "Новые вводные",
        "code": 2
    },
    "meeting_core": "Еще одна встреча",
    "meeting_points": [{
        "description": "как можно использовать обнаруженное в текстах в планировании нашего исследования",
        "status": "undone"
    }, {
        "description": "Получаем ОС",
        "status": "undone"
    }, {
        "description": "Решаем, что будет входить в ТЗ и утверждаем его структуру",
        "status": "undone"
    }, {
        "description": "Как они могут закрыть определенные потребности в проектах Лаборатории",
        "status": "undone"
    }, {
        "description": "Рассказываем, откуда брали данные",
        "status": "undone"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-04-16 09:00:00+03:00",
        "before_time": "2021-04-16 09:00:00+03:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-04-16 09:30:00+03:00",
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": []
}, {
    "id": "acf13d980e15dbacb763fe346fc63a78",
    "source": "outlook",
    "meeting_type": {
        "type": "Принимаем результаты",
        "code": 1
    },
    "meeting_core": "Планируем деятельность блока ОП",
    "meeting_points": [{
        "description": "Решаем, что будет следующим шагом",
        "status": "undone"
    }, {
        "description": "Рассказываем, откуда брали данные",
        "status": "undone"
    }, {
        "description": "Выбираем формат рекомендаций",
        "status": "undone"
    }],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"],
    "meeting_wanted_window": {
        "before_date": "2021-04-16 09:00:00+03:00",
        "before_time": "2021-04-16 09:00:00+03:00",
        "duration": "PT2M10S"
    },
    "meeting_agreed_datetime": moment().add(10, 'seconds'),
    "meeting_place": "zoom",
    "meeting_prepare": ["отчет о проделанной работе", "презентацию"],
    "stenography": []
}]

export let demo_meetings1 = [{
    "id": "2_ABaOnudnzC3TYIdIMM8JbRZ5ZvqrpAHh2i8uW4zNFdfjRvQ4-ewG05w21LCYI4vDlXAVCU0",
    "meeting_type": {
        "type": "Поиск решения",
        "code": 2
    },
    "meeting_core": "Работа с курсом по Психотипам",
    "meeting_points": ["Добавить в образ результата:\n- надо научить директоров учить других\n- учить они будут в онлайне\n- научить их коммуницировать на основе психотипо", "Написать образовательные цели", "Поработать над программой - проапгрейдить предыдущую"],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "meeting_additional_participants": ["Екатерина Маслякова, Лаборатория нейронаук"],
    "meeting_wanted_window": {
        "before_date": "2021-02-25",
        "before_time": "19:00",
        "duration": 'PT30M'//"1,5 часа"
    },
    "meeting_agreed_datetime": moment().add(1, 'days'), //"2021-02-24 19:00",
    "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
    "meeting_prepare": null
}, {
    "id": "2_ABaOnudZwyMd105xzBYGKV7Q3BUVJd5icVtdHX5CcbFhwGUv5EGBXY4t8XoKLSIrLjvx7qk",
    "meeting_type": {
        "type": "Новые вводные",
        "code": 4
    },
    "meeting_core": "Новые вводные по курсу для рВСП",
    "meeting_points": ["Расскажу о неожиданных новостях после встречи с заказчиком"],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "meeting_additional_participants": [],
    "meeting_wanted_window": {
        "before_date": "2021-03-02",
        "before_time": "15:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": "2021-03-27 15:00",
    "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
    "meeting_prepare": "вспомнить предыдущую программу этого курса"
},{
    "id": "2_ABaOnudZwyMd105xzBYGKV7Q3BUVJd5icVtdHX5CcbFhwGUv5EGBXY4t8XoKLSIrLjvx7qk",
    "meeting_type": {
        "type": "Новые вводные",
        "code": 4
    },
    "meeting_core": "Новые вводные по курсу для рВСП",
    "meeting_points": ["Расскажу о неожиданных новостях после встречи с заказчиком"],
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "meeting_additional_participants": [],
    "meeting_wanted_window": {
        "before_date": "2021-03-02",
        "before_time": "15:00",
        "duration": "PT30M"
    },
    "meeting_agreed_datetime": moment().add(3, 'days'),
    "meeting_place": "https://us04web.zoom.us/j/7597803668?pwd=UklMdUwvYmN5QVlXa0phUllpNzNjQT09",
    "meeting_prepare": "вспомнить предыдущую программу этого курса"
}, {
    "id": "AAMkADAwZDQ0YWQ4LThkZTAtNDdkNS05NGQ3LTZjY2IyZTkzZGQ3YQBGAAAAAAAT3QJqyZxLRK+1QjUZ3XaEBwAxX+x2uMJnSaHsOWnoP8tYAAAAAAEMAAAxX+x2uMJnSaHsOWnoP8tYAACCjUWyAAA=", 
    "meeting_type": {
        "type": "Синхронизация", 
        "code": 3
    }, 
    "meeting_core": "Демо Встреча", 
    "meeting_points": ["рассказать о сделанном"], 
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": ["Vasichkin.E.Ol@sberbank.ru"], 
    "meeting_wanted_window": {
        "before_date": "2021-03-16 09:00:00+03:00", 
        "before_time": "2021-03-16 09:00:00+03:00", 
        "duration": "PT30M"
    }, 
    "meeting_agreed_datetime": "2021-03-16 09:30:00+03:00", 
    "meeting_place": "Zoom", 
    "meeting_prepare": ["отчет о проделанной работе", 
    "презентацию"]
}, {
    "id": "AAMkADAwZDQ0YWQ4LThkZTAtNDdkNS05NGQ3LTZjY2IyZTkzZGQ3YQBGAAAAAAAT3QJqyZxLRK+1QjUZ3XaEBwAxX+x2uMJnSaHsOWnoP8tYAAAAAAEMAAAxX+x2uMJnSaHsOWnoP8tYAACCjUW1AAA=", 
    "meeting_type": {
        "type": "Поиск решения", 
        "code": 2
    }, 
    "meeting_core": "Демо Тест", 
    "meeting_points": [
        "решить вопросы", 
        "продумать дальнейший план действий"
    ], 
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    "sent_protocol_to": [], 
    "meeting_wanted_window": {
        "before_date": "2021-03-16 10:00:00+03:00", 
        "before_time": "2021-03-16 10:00:00+03:00", 
        "duration": "PT1H"
    }, 
    "meeting_agreed_datetime": "2021-03-16 11:00:00+03:00", 
    "meeting_place": "https://zoom.us/j/94826654256?pwd=OVRCZG9YRDVSQ2R2MU1EWXNHOVgxUT09", 
    "meeting_prepare": ["вопросы Егору"]
},{
    "id": "AAMkAfdfDAwZDQ0YWQ4LThkZTAtNDdkNS05NGQ3LTZjY2IyZTkzZGQ3YQBGAAAAAAAT3QJqyZxLRK+1QjUZ3XaEBwAxX+x2uMJnSaHsOWnoP8tYAAAAAAEMAAAxX+x2uMJnSaHsOWnoP8tYAACCjUW1AAA=", 
    "meeting_type": {
        "type": "Поиск решения", 
        "code": 2
    }, 
    "meeting_core": "Тестовая встреча с большим заголовком", 
    "meeting_points": [
        "решить вопросы", 
        "решить вопросы", 
        "решить вопросы", 
        "продумать дальнейший план действий"
    ], 
    meeting_participants: [
        {
            description: "Степнов Михаил Игоревич - MIStepnov@sberbank.ru",
            email: "MIStepnov@sberbank.ru",
            status: "0"
        },
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru",
            email: "Vasichkin.E.Ol@sberbank.ru",
            status: "0"
        }
    ],
    //     "Смирнов Иван Юрьевич - Smirnov.I.Yurye@sberbank.ru", 
    //     "Цифровой менеджер - digital-manager@sberbank.ru", 
    //     "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"
    // ], 
    "sent_protocol_to": [], 
    "meeting_wanted_window": {
        "before_date": "2021-03-26 10:00:00+03:00", 
        "before_time": "2021-03-26 10:00:00+03:00", 
        "duration": "PT15M"
    }, 
    "meeting_agreed_datetime": moment(), //"2021-03-29 11:00:00+03:00", 
    "meeting_place": "https://zoom.us/j/94826654256?pwd=OVRCZG9YRDVSQ2R2MU1EWXNHOVgxUT09", 
    "meeting_prepare": ["вопросы Егору"]
}]



export let demo_recognize = [
    {id:-1,blob:null,url:null,filename:null,recognize:{
    	"distortions": [],
    	"fragment": "Аутсорсинг, выведение за рамки предприятий.",
    	"total_text": "Дубль два глоссарийАутсорсинг, выведение за рамки предприятий.Я традиционных для компании вспомогательных функций.Который фирмы профессионалы делают лучше и с меньшимиСтешками. Бизнес дел или занятьНе приносящей доход управления эффективностью"
    }},
    {id:-2,blob:null,url:null,filename:null,recognize:{
    	"distortions": ["Отклонение в сторону статуса кво", "Ошибка планирования"],
    	"fragment": "Я традиционных для компании вспомогательных функций.",
    	"total_text": "Дубль два глоссарийАутсорсинг, выведение за рамки предприятий.Я традиционных для компании вспомогательных функций.Который фирмы профессионалы делают лучше и с меньшимиСтешками. Бизнес дел или занятьНе приносящей доход управления эффективностьюБизнесмен. Человек дело операторМечты выгоды бизнесмен действовать только исключительноРади прибыли"
    }},
    {id:-3,blob:null,url:null,filename:null,recognize:{
    	"distortions": [],
    	"fragment": "Мечты выгоды бизнесмен действовать только исключительно",
    	"total_text": "Дубль два глоссарий. Аутсорсинг — выведение за рамки предприятий (!) традиционных для компании вспомогательных функций, который фирмы профессионалы делают лучше и с меньшимиСтешками.  Бизнес дел или занятьНе приносящей доход управления эффективностьюБизнесмен. Человек дело операторМечты выгоды бизнесмен действовать только исключительноРади прибыли"
    }},
    {id:-4,blob:null,url:null,filename:null,recognize:{
    	"distortions": [],
    	"fragment": "Аутсорсинг, выведение за рамки предприятий.",
    	"total_text": "Дубль два глоссарийАутсорсинг, выведение за рамки предприятий.Я традиционных для компании вспомогательных функций.Который фирмы профессионалы делают лучше и с меньшимиСтешками. Бизнес дел или занятьНе приносящей доход управления эффективностью"
    }},
    {id:-5,blob:null,url:null,filename:null,recognize:{
    	"distortions": [], //["Ошибка планирования", "Искажение коллективной ответственности"],
    	"fragment": "Я традиционных для компании вспомогательных функций.",
    	"total_text": "Дубль два глоссарийАутсорсинг, выведение за рамки предприятий.Я традиционных для компании вспомогательных функций.Который фирмы профессионалы делают лучше и с меньшимиСтешками. Бизнес дел или занятьНе приносящей доход управления эффективностьюБизнесмен. Человек дело операторМечты выгоды бизнесмен действовать только исключительноРади прибыли"
    }},
    {id:-6,blob:null,url:null,filename:null,recognize:{
    	"distortions": [],
    	"fragment": "Аутсорсинг, выведение за рамки предприятий.",
    	"total_text": "Дубль два глоссарийАутсорсинг, выведение за рамки предприятий.Я традиционных для компании вспомогательных функций.Который фирмы профессионалы делают лучше и с меньшимиСтешками. Бизнес дел или занятьНе приносящей доход управления эффективностью"
    }},
    {id:-7,blob:null,url:null,filename:null,recognize:{
    	"distortions": [],
    	"fragment": "Аутсорсинг, выведение за рамки предприятий.",
    	"total_text": "Дубль два глоссарийАутсорсинг, выведение за рамки предприятий.Я традиционных для компании вспомогательных функций.Который фирмы профессионалы делают лучше и с меньшимиСтешками. Бизнес дел или занятьНе приносящей доход управления эффективностью"
    }},
    {id:-8,blob:null,url:null,filename:null,recognize:{
    	"distortions": [],//['Эффект ложного консенсуса'],
    	"fragment": "Мечты выгоды бизнесмен действовать только исключительно",
    	"total_text": "Дубль два глоссарий. Аутсорсинг — выведение за рамки предприятий (!) традиционных для компании вспомогательных функций, который фирмы профессионалы делают лучше и с меньшимиСтешками.  Бизнес дел или занятьНе приносящей доход управления эффективностьюБизнесмен. Человек дело операторМечты выгоды бизнесмен действовать только исключительноРади прибыли"
    }}
];


export default {
    demo_meetings, 
    demo_recognize
};