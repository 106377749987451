import React from 'react';
import {
    Container, Row, Col, 
    // Cell, CellIcon,
    Header,
    // Card,
    // CardBody,
    // CardContent,
    // CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    // TextBoxBigTitle,
    // TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    Tabs, TabItem,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import {
    IconApps,
    IconCalendar,
    IconHouse,
    IconHouseSbol,
    IconCardstack

 } from '@sberdevices/plasma-icons';

import {
//     BrowserRouter as Router,
//     Switch,
    Route,
    Link,
//     useParams,

    Redirect,
    useHistory
  } from "react-router-dom";
  

import MeetCards from './cards.js'
import SingleCard from './single.js'
import Calendar from './calendar.js'

import { Progress } from 'antd';

import { Row as Row2, Col as Col2 } from 'antd';

 

const coverImgUrl = 'https://5f96ec813d800900227e3b93-sdshhvdjxe.chromatic.com/images/320_320_0.jpg';


let MyTabs = (props) => {
    const [index, setIndex] = React.useState(props.index);

    let setInd = (id) => {
        setIndex(id)
        props.onChange(id)
    }

    return (
        <Tabs style={props.style} size={'l'} view={'clear'} pilled={false} scaleOnPress={true} outlined={false}>
                <TabItem isActive={'house' === index} onClick={() => setInd('house')} contentLeft={<IconHouseSbol color={'house' === index ? 'white' : '#ffffff50' } size="s" />}></TabItem>
                <TabItem isActive={'cards' === index} onClick={() => setInd('cards')} contentLeft={<IconCardstack color={'cards' === index ? 'white' : '#ffffff50' } size="s" />}></TabItem>
                <TabItem isActive={'calendar' === index} onClick={() => setInd('calendar')} contentLeft={<IconCalendar color={'calendar' === index ? 'white' : '#ffffff50' } size="s" />}></TabItem>
        </Tabs>
    );
    // IconPersone,
    //     IconAlarm,
    //     IconHouseSbol,
    //     IconCardstack,
    //     IconMessage,
    //     IconInfo,
    //     IconSettings,
}

function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }


// let gotoLogin = () => {
//     let path = '/login';
//     let history = useHistory();
//     history.push(path);
// }

// function LogoutBtn() {
//     let history = useHistory();
//     return (
//         <Button view={"clear"} tabIndex={1} size="s" onClick={() => {history.push('/login')} }>
//             Выход
//         </Button> 
//     )
// }

// function MeetList(props) {
export class MeetList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: new Date(),
            index: 'cards',
        }
    }
    componentDidMount() {
        this._iid = setInterval(() => this.update_now(), 1000);
        this.update_now();
    }
    componentWillUnmount() {
        clearInterval(this._iid);
    }
    update_now() {
        this.setState({ now: new Date() })
    }
    setIndex = (i) => {
        this.setState({index: i})
    }

    render() {
        let now = this.state.now;

        // const [index, setIndex] = React.useState('calendar');
        // const [index, setIndex] = React.useState('cards');

        return (
            <Container>
                {/* <Progress strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
                                    percent={0} showInfo={false}/> */}
                <div style={{height:'22px'}}></div>

                <Row2 gutter={60} align="middle" style={{marginBottom:'30px'}}>
                    <Col2 style={{width:'calc(375px + 1rem)'}} >
                        <Header
                            back={false}
                            // logo={coverImgUrl}
                            // logoAlt="Logo"
                            title={<img src={"/logo_sdm.png"} width="120" />}
                            // subtitle={'Все ваши встречи'}
                            ></Header>
                        
                    </Col2>
                    <Col2 flex="auto">
                        {/* <h3>Встречи</h3> */}
                        <MyTabs style={{marginLeft:'-1rem'}} index={this.state.index} onChange={(i) => this.setIndex(i)} />   
                    </Col2>
                    <Col2 style={{textAlign:'right'}} >
                        <Button view={"clear"} tabIndex={1} size="s" onClick={() => {window.open('https://docs.google.com/forms/d/1OS_MOBRbYxfQWfvGx63H80Z73pvwf2EOGx_29rjwFdY/edit', '_blank').focus()}}>
                            Добавить встречу
                        </Button> 
                        <Button view={"clear"} tabIndex={1} size="s" onClick={() => {toggleFullScreen()}}>
                            Полный экран
                        </Button> 
                        {/* <Button view={"clear"} tabIndex={1} size="s" onClick={() => Route.history.push("/login") }> */}
                        {/* <Button view={"clear"} tabIndex={1} size="s" onClick={() => <Link to="/login" /> }> */}
                        {/* <Button view={"clear"} tabIndex={1} size="s" onClick={() => this.context.history.push('/dashboard') }> */}
                        {/* <Button view={"clear"} tabIndex={1} size="s" onClick={() => <Redirect to="/login" /> }> */}
                        {/* <Button view={"clear"} tabIndex={1} size="s" onClick={() => {window.ME = this; console.log(this)} }>
                            Выход
                        </Button>  */}
                        {/* <LogoutBtn/> */}
                        <Button view={"clear"} tabIndex={1} size="s" onClick={() => this.props.onLogout() }>
                            Выход
                        </Button> 
                    </Col2>
                </Row2>

                {/* <Header
                    back={false}
                    // logo={coverImgUrl}
                    logoAlt="Logo"
                    title={'Встречи'}
                    // subtitle={'Все ваши встречи'}
                >
                    <Button view={"clear"} tabIndex={1} size="s" onClick={() => {window.open('https://docs.google.com/forms/d/1OS_MOBRbYxfQWfvGx63H80Z73pvwf2EOGx_29rjwFdY/edit', '_blank').focus()}}>
                        Добавить встречу
                    </Button> 
                    <Button view={"clear"} tabIndex={1} size="s" onClick={() => {toggleFullScreen()}}>
                        Полный экран
                    </Button> 
                </Header> */}
                {/* <MyTabs index={index} onChange={(i) => setIndex(i)} />    */}

                {/* <MeetCards list={this.props.list} /> */}
                { this.state.index === 'cards' ? <MeetCards list={this.props.list} /> : '' }
                { this.state.index === 'calendar' ? Calendar : '' }
                { this.state.index === 'house' ? <SingleCard list={this.props.list} /> : '' }


                
                
        
            </Container>    
        )
    }
}


export default MeetList;