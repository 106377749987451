
// import { spacing } from '@sberdevices/plasma-core/mixins';
// import { split } from 'lodash';
import moment from 'moment'
import 'moment/min/locales';

import RecorderList from '../MeetingsPage/rec/RecorderList.js'

export function microTypographer(text, withHtmlWrapper = true) {
    // let p = ['у','в','на','по','до','к','с','и','за'];

    // p.forEach(x => {
    //     let pattern = ' ' + x + ' ';
    //     let 
    //     text.replace(/ у /g,' e')
    // })

    let t = text.replace(/ (у|в|о|на|по|во|до|к|с|и|за|для|vs|из|под|его|её) /gi, ' $1&nbsp;');
    t = t.replace(/ ([^ ]{1,5})$/, '&nbsp;$1');
    
    if (!withHtmlWrapper) return t

    return <span dangerouslySetInnerHTML={{ __html:t }}/>
}


function num_postfix_word(num, words = 'час_часа_часов') {
    if (num == 0) return ''
    let w = words.split('_')
    let n = num > 10 && num < 20 ? 0 : num 
    switch(n % 10) {
        case 1: 
            return num + ' ' + w[0] 
        case 2: 
        case 3: 
        case 4: 
            return num + ' ' + w[1];
        case 5: 
        case 6: 
        case 7: 
        case 8: 
        case 9: 
        case 0: 
            return num + ' ' + w[2];
    }
}

export function cardStatus(meet) {

    moment.locale('ru');
    moment.updateLocale('ru', {
        calendar: {
            nextWeek: 'L',
            lastWeek: 'L'
        },
        relativeTime: {
            h: '1 час',
            d: '1 день',
        }
    })

    let LASTTIME_FRAME = 2;

    let datetime = meet.meeting_agreed_datetime
    let duration = meet.meeting_wanted_window.duration

    let now = moment();
    let begin = moment(datetime).locale('ru')
    let end = moment(datetime).add( moment.duration(duration).as('seconds'), 'seconds').locale('ru')
    let d = moment.duration(duration).locale('ru');

    let dh_text = num_postfix_word( d.hours(), 'час_часа_часов');
    let dm_text = num_postfix_word( d.minutes(), 'минута_минуты_минут');
    let d_txt = dh_text + ' ' + dm_text

    // view : "accent" | "primary" | "secondary" | "warning" | "critical" | "checked" | "clear" | "overlay"

    let fromNow = begin.fromNow();
    // let display_active = fromNow == 'через несколько секунд' ? fromNow : 'начнется<br/>' + fromNow
    let display_active = 'начнется<br/>' + fromNow.replace(/([0-9]{1,}) /, '$1&nbsp;')
    if (display_active == 'начнется<br/>через несколько секунд') {
        // display_active = 'начнется через несколько секунд'
        display_active = 'несколько секунд до&nbsp;начала'
    }
    let status = { 
        type: 'WILL_BE', 
        title:'ожидается', 
        view: 'checked',
        // active_full: 'начнется ' + fromNow + ', ' + begin.calendar().toLowerCase() + ' — ' + d_txt,
        // active_full: (begin - now) / 1000 / 60 < 1 ? fromNow : 'начнется ' + fromNow,
        active_full: 'начнется ' + fromNow,
        // active_full: begin.calendar() + ' — ' + d_txt,
        active_mini: fromNow,
        datatime_plus_d: begin.calendar() + ' — ' + d_txt,
        left_minutes: (end - now) / 1000 / 60,
        right_minutes: (begin - now) / 1000 / 60,
        //  начнется через несколько секунд
        display_active: <span dangerouslySetInnerHTML={{__html: display_active }} />,
    }
    if (begin < now) {
        // let fromNow = begin.fromNow();
        let left_minutes = (end - now) / 1000 / 60
        // let display_active = 'закончится<br/>через ' + (num_postfix_word( Math.ceil( left_minutes ), 'минуту_минуты_минут')?.replace(' ','&nbsp;')|| 'несколько секунд')
        let display_active = (num_postfix_word( Math.ceil( left_minutes ), 'минута_минуты_минут')?.replace(' ','&nbsp;') || 'несколько секунд') + ' до&nbsp;конца'
        // if (display_active == 'закончится<br/>через несколько секунд') {
        if (left_minutes < 0.5) {
            display_active = 'несколько секунд до&nbsp;конца'
        }
        status = { 
            type: 'GOES', 
            title:'идет', 
            view: 'primary',
            active_full: 'закончится ' + end.fromNow(),
            active_mini: end.fromNow(),
            datatime_plus_d: begin.calendar() + ' — ' + d_txt,
            left_minutes: left_minutes,
            right_minutes: (begin - now) / 1000 / 60,
            display_active: <span style={{color: left_minutes > LASTTIME_FRAME ? 'white' : '#ffa800'}} dangerouslySetInnerHTML={{__html: display_active  }} />,
            // display_active: <span style={{color: (end - now) / 1000 / 60 > 5 ? 'white' : '#ffa800'}} dangerouslySetInnerHTML={{__html: 'закончится<br/>' + end.fromNow() }} />,
            // display_active: (end - now) / 1000 / 60, //<span style={{color: (end - now) / 1000 / 60 < 5 ? 'white' : '#ffa800'}} dangerouslySetInnerHTML={{__html: 'закончится<br/>' + end.fromNow() }} />,
        } 
    }
    // if (begin < now) status = { type: 'GOES', title:'идет', view: 'checked' }
    // let lefttime = Math.round( (status.end - now) / 1000 / 60, 1 )
    if (end < now) {
        let display_active = 'закончилась<br/>' + end.fromNow()
        if (display_active == 'закончилась<br/>несколько секунд назад') {
            display_active = 'несколько секунд назад'
        }
        status = { 
            type: 'ENDED', 
            title:'закончилась', 
            view: 'secondary',
            active_full: 'закончилась ' + end.fromNow(),
            // active_mini: end.fromNow()
            active_mini: (now - end) / 1000 / 60 / 60 < 12 ? end.fromNow() : end.calendar().toLowerCase(),
            datatime_plus_d: begin.calendar() + ' — ' + d_txt,
            left_minutes: (end - now) / 1000 / 60,
            right_minutes: (begin - now) / 1000 / 60,
            display_active: <span dangerouslySetInnerHTML={{__html: display_active }} />,
        }
    }

    status.begin = begin;
    status.end = end;
    status.d = d;
    status.d_txt = d_txt;

    return status
}


export function meet(meet_from_api) {
    // meet_from_api.id = meet_from_api.meeting_id;
    // console.log('MEET', meet_from_api.stenography, meet_from_api.meeting_core)

    meet_from_api.reclist = new RecorderList(meet_from_api.id || -1, meet_from_api.stenography || [])
    meet_from_api.status = cardStatus(meet_from_api);

    // meet_from_api.players
    meet_from_api.members = [
        ...(meet_from_api.meeting_participants || []).map(x => ({ 
            fullName: x.description.replace(/ - /g, ',').split(',')[0],
            mail: x.description.replace(/ - /g, ',').split(',')[1],
            email: x.email,
            status: x.status,
            type: 'main',
        })),
        ...(meet_from_api.meeting_additional_participants || []).map(x => ({ 
            fullName: x.description.replace(/ - /g, ',').split(',')[0],
            mail: x.description.replace(/ - /g, ',').split(',')[1],
            email: x.email,
            status: x.status,
            type: 'additional',
        })),
    ]

    meet_from_api.tasks = [
        ...(meet_from_api.meeting_points || []).map(x=> ({
            title: <span dangerouslySetInnerHTML={{__html: microTypographer(x.description, false)
                        .replace(/\n/g, '<br/> ')
                        .replace(/ - /g, ' — ')
                        .replace(/^[0-9]*\. /, '') 
                    }} /> ,
            status: x.status == 'done'
        }))
    ]

    if ( meet_from_api.meeting_id ) meet_from_api.id = meet_from_api.meeting_id;

    // console.warn('LOAD RecorderList', meet_from_api.reclist)

    

    return meet_from_api
    // return { 
    //     id:         meet_from_api.id,
    //     title:      meet_from_api.meetings_cores,
    //     type_title: meet_from_api.meetings_type.type,
    //     type_code:  meet_from_api.meetings_type.type_code,
    //     status:     cardStatus(meet_from_api),
    //     tasks:      meet_from_api.meetings_points,
    //     link:       meet_from_api.meeting_place,
    // }
}   

/*
    id: "76ca6325bd70c39964838541982d444f"
    meeting_agreed_datetime: "2021-04-07 09:30:00+03:00"
    meeting_core: "Новые данные о сроках курса и его заказчиках"
    meeting_id: "76ca6325bd70c39964838541982d444f"
    meeting_participants: [
        {
            description: "Смирнов Иван Юрьевич - Smirnov.I.Yurye@sberbank.ru"
            email: "Smirnov.I.Yurye@sberbank.ru"
            status: "0"
        }, {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"
            email: "Vasichkin.E.Ol@sberbank.ru"
            status: "0"
        }
    ]
    meeting_place: "zoom"
    meeting_points: [
        {
            description: "После встречи с заказчиками ... на встрече."
            status: "done"
        }
    ]
    meeting_prepare: [
        {
            description: "презентацию"
        }
    ]
    meeting_type: {
        code: 4
        type: "Новые вводные"
    }
    meeting_wanted_window: {
        before_date: "2021-04-07 09:00:00+03:00"
        before_time: "2021-04-07 09:00:00+03:00"
        duration: "PT30M"
    }
    sent_protocol_to: [
        {
            description: "Васичкин Евгений Олегович - Vasichkin.E.Ol@sberbank.ru"
            email: "Vasichkin.E.Ol@sberbank.ru"
        }
    ]
    source: "outlook"

*/
