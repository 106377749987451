

import React, { useState } from 'react';

import {
    Container, Row, Col, 
    // Cell, CellIcon, CellListItem,
    Header,

    // HeaderRoot,
    // HeaderBack,
    // HeaderLogo,
    // HeaderTitleWrapper,
    // HeaderTitle,
    // HeaderSubtitle,
    // HeaderContent,

    Card,
    CardBody,
    CardContent,
    CardMedia,
    CardHeadline1,
    CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    TextBoxSubTitle,
    // TextBoxTitle,
    // Badge,
    Tabs, TabItem,
    // Display2,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    // ActionButton,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    Underline,
    Spinner
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import { Progress, notification, message } from 'antd';

 import {
//     IconEye,
//     IconSleep,
        IconPlusCircle,
        IconMic,
        IconMicNone,
        // IconMicOff,
        // IconPersone,
        // IconAlarm,
        // IconHouseSbol,
        // IconCardstack,
        // IconMessage,
        // IconInfo,
        // IconSettings,
        IconDownload,
        // IconKeyboard,
        // IconTrash,
        // IconFeedback,
        // IconSpinner,
        
        IconPower,
        IconClose,
 } from '@sberdevices/plasma-icons';

//  LoadingOutlined

// import RecorderList from './rec/RecorderList.js'
 
// import { Modal } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { ThunderboltOutlined } from '@ant-design/icons';
import { PushpinFilled, MailOutlined, AudioOutlined } from '@ant-design/icons';

import { demo_meetings, demo_recognize } from './../../api/demodata.js'

// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
//     useHistory
//   } from "react-router-dom";

import './rec.css'

import AboutKI from './AboutKI'
import TextRecFragment from './TextRecFragment'
  

import api from '../../api/api.js'
// import kilib from '../../dict/ki.js' 
// import { Content } from 'antd/lib/layout/layout';

// const coverImgUrl = 'https://5f96ec813d800900227e3b93-sdshhvdjxe.chromatic.com/images/320_320_0.jpg';
const coverImgUrl = '/320_320_0.jpg';



//
// TextRecList
//

export class TextRecList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.reclist.list || [],
            activeFragmentId: -1
        }   
        // this.activeFragmentId = -1;
    }
    componentDidMount() {
        this.props.reclist.addChangeListener ((e) => {
            // console.log('ChangeChangeChange', e)
            this.setState({ list: e.detail.list }) 
        })
    }

    onPlay = (id) => {
        // this.activeFragmentId = id
        this.setState({ activeFragmentId: id }) 
    }

    renderItem = (item, index) => {
        return <TextRecFragment item={item} key={index} 
                    reclist={this.props.reclist} 
                    onPlay={this.onPlay}
                    activeId={this.state.activeFragmentId} />
    }

    render() {
        return (
            <div className="TextRecList">               
                {this.state.list.map(this.renderItem)}  
            </div>
        )
    }
}


export class ReportRecList extends TextRecList {

    onSendMail = () => {
        api.create_mail(this.props.meet.id).then(res => {
            console.log('on_create_mail', res)
            notification.open({
                message: 'Отправка протокола',
                description: res,
                icon: <MailOutlined/>, 
            });
        }, err => {
            console.log('on_err_create_mail', err)
            notification.open({
                message: 'Ошибка отправки протокола',
                description: JSON.stringify(err),
                icon: <MailOutlined/>,
            });
        })
    }

    render() {
        let minutes = this.state.list.filter(x=> x.isCommand == true);

        if ( minutes.length > 0 ) {
            return (
                <div>
                    <Button onClick={()=> api.get_minutes_file(this.props.meet)}>Скачать</Button>
                    &nbsp;
                    <Button onClick={this.onSendMail}>
                        {/* contentLeft={<MailOutlined/>}  pin="circle-circle" */}
                        Отправить почтой
                    </Button>
                    {/* <Button view="primary" style={{background: '#108ee9', fontSize:'24px'}} size="l" pin="circle-circle" 
                        contentLeft={<MailOutlined />} /> */}
                    <br/>
                    <br/>
                    <div className="TextRecList ReportRecList">  
                        {minutes.map((x,i) => 
                            <div key={i} className="ReportRecList-item">
                                {this.renderItem(x, i)} 
                            </div>
                        )} 
                    </div>
                    <br/>
                    <br/>
                </div>
            )
        } else {
            return (
                <div>Нет записей</div>
            )
        }

    }
}

//
// PlayStopBtn
//

export class PlayStopBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlay: false,
            isCommand: false,
            v: 0,
            showAlarm: false
        }
    }
    componentDidMount() {
        this.props.reclist.addVolumeListener (this.onVolume)
    }
    componentWillUnmount() {
        this.onStop()
        this.props.reclist.removeVolumeListener (this.onVolume)
    }

    onVolume = (e) => {
        this.setState({ v: e.detail.v }) 
    
        if (e.detail.v > 85 && this.state.showAlarm == false) {
            this.setState({ showAlarm: true }) 
            notification.open({
                // duration: 0,
                message: 'Пожалуйста, потише!',
                placement: 'bottomRight',
            //     // description: '123',
                icon: <AudioOutlined style={{color:'red'}} />, 
            });
            // message.error( 'Пожалуйста, потише!' );
        }
        if (e.detail.v < 10 && this.state.showAlarm == true) {
            this.setState({ showAlarm: false })
        }
    }

    onPlay = () => {
        this.props.reclist.startRecording(this.props.meet_id, 2000).then(() => {
        // this.props.reclist.startRecording(this.props.meet_id, 10000).then(() => {
        // this.props.reclist.startRecording(10000).then(() => {
            this.setState({ isPlay: true })
        })
    }
    onStop = () => {
        this.onEndCommand();
        this.props.reclist.stopRecording()
        this.setState({ isPlay: false });
    }
    onBeginCommand = () => {
        this.setState({ isCommand: true });
        this.props.reclist.beginCommand()
    }
    onEndCommand = () => {
        this.setState({ isCommand: false });
        this.props.reclist.endCommand()
    }
    render() {
        return !this.state.isPlay ? 
            (<Button view="secondary" pin="circle-circle" onClick={this.onPlay}>
                {/* <IconMicNone /> Start */}
                <IconPower />&nbsp;Старт
            </Button>) :
            (<nobr>
                {/* <Button view="primary" pin="circle-circle" onClick={this.onStop} style={{ */}
                <Button view="secondary" pin="circle-circle" onClick={this.onStop} style={{
                    // background: 'linear-gradient( 3deg, #9a181800, #9a181800, #b00e0e)',
                    backgroundImage: 'linear-gradient( 0deg, #b00e0e, yellow, #2AC673 50%, #9a181800 50%, #9a181800)',
                    // backgroundImage: 'url(/320_320_0.jpg)',
                    backgroundSize: '200px 112px',
                    // backgroundSize: '200px 80px',
                    backgroundRepeat: 'no-repeat',
                    // backgroundSize: '200%',
                    backgroundPositionY: (-this.state.v/100*56) + 'px'
                    // backgroundPositionY: (-this.state.v/100*40) + 'px'
                }}>
                    {/* <IconMic /> Stop */}
                    <IconPower />&nbsp;Стоп
                </Button>
                &nbsp;
                &nbsp;
                {this.renderBtnCommand()}
            </nobr>)
    } 
    renderBtnCommand() {
        return !this.state.isCommand ? 
            (<Button view="secondary" size="l" pin="circle-circle" style={{fontSize:'24px'}}
                onClick={this.onBeginCommand} contentLeft={<PushpinFilled />} />) :
            (<Button view="primary" style={{background: '#108ee9', fontSize:'24px'}} size="l" pin="circle-circle" 
                onClick={this.onEndCommand} contentLeft={<PushpinFilled />} />)
    }

    // <ActionButton size={'l'} view={'primary'}>
    //     <IconMic />
    // </ActionButton>
}


//
// ClearHistBtn
//

export class ClearHistBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.reclist ? this.props.reclist.list || [] : []
        }
    }
    componentDidMount() {
        this.props.reclist.addChangeListener ((e) => {
            this.setState({ list: e.detail.list }) 
        })
    }
    onClear = () => {
        console.log('ClearHistBtn')
        api.clear_history_total_text(this.props.meet.id)
        this.props.reclist.clear();
    }

    render() {
        return this.state.list.length > 0 ? (
            <Button view="secondary" pin="circle-circle" onClick={this.onClear}>
                <IconClose /> Очистить историю
            </Button>
        ) : ''
    }
}

//
// SetDemo
//

export class SetDemo extends React.Component {
    constructor(props) {
        super(props);
    }
    onSetDemo = () => {
        console.log('onSetDemo')
        // api.clear_history_total_text()
        demo_recognize.forEach( x => this.props.reclist.list.push(x) )
        // this.props.reclist.list = demo_recognize
        this.props.reclist.onChange();
    }

    render() {
        return (
            <Button view="secondary" pin="circle-circle" onClick={this.onSetDemo}>
               <IconDownload /> Demo
            </Button>
        ) 
    }
}


//
// ClearHistBtn
//

// export class VolumeLevel extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             v: 0,
//             showAlarm: false
//         }
//     }
//     componentDidMount() {
//         this.props.reclist.addVolumeListener (this.onVolume)
//     }
//     onVolume = (e) => {
//         this.setState({ v: e.detail.v }) 
    
//         if (e.detail.v > 50 && this.state.showAlarm == false) {
//             this.setState({ showAlarm: true }) 
//             notification.open({
//                 message: 'Пожалуйста, потише!',
//                 description: '123',
//                 // icon: <MailOutlined/>, 
//             });
//         }
//         if (e.detail.v < 10 && this.state.showAlarm == true) {
//             this.setState({ showAlarm: false })
//         }
//     }
//     // onClear = () => {
//     //     console.log('ClearHistBtn')
//     //     api.clear_history_total_text(this.props.meet.id)
//     //     this.props.reclist.clear();
//     // }

//     render() {
//         return (
//             <>

//             <Button view="secondary" style={{
//                 // background: 'linear-gradient( 3deg, #9a181800, #9a181800, #b00e0e)',
//                 backgroundImage: 'linear-gradient( 0deg, #b00e0e, yellow, #2AC673 50%, #9a181800 50%, #9a181800)',
//                 // backgroundImage: 'url(/320_320_0.jpg)',
//                 backgroundSize: '200px 112px',
//                 backgroundRepeat: 'no-repeat',
//                 // backgroundSize: '200%',
//                 backgroundPositionY: (-this.state.v/100*56) + 'px'
//             }}>
//                 {/* {this.state.v} */}
//                 Старт
//             </Button>
//             <Progress strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
//                 percent={this.state.v} showInfo={false}/>
//             </>
//         ) 
//     }
// }
