

import React, { useState } from 'react';

import {
    // Container, Row, Col, 
    // Cell, CellIcon, CellListItem,
    // Header,

    // HeaderRoot,
    // HeaderBack,
    // HeaderLogo,
    // HeaderTitleWrapper,
    // HeaderTitle,
    // HeaderSubtitle,
    // HeaderContent,

    // Card,
    // CardBody,
    // CardContent,
    // CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    // TextBoxBigTitle,
    // TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // TextBoxTitle,
    // Badge,
    // Tabs, TabItem,
    // Display2,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    // TextBox,
    // Button,
    // ActionButton,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Underline,
    Spinner
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';



 import {
// //     IconEye,
// //     IconSleep,
//         IconPlusCircle,
        // IconMic,
        IconMicNone,
//         // IconMicOff,
//         // IconPersone,
//         // IconAlarm,
//         // IconHouseSbol,
//         // IconCardstack,
//         // IconMessage,
//         // IconInfo,
//         // IconSettings,
//         IconDownload,
//         // IconKeyboard,
//         // IconTrash,
//         // IconFeedback,
//         // IconSpinner,
        
//         IconPower,
//         IconClose,
 } from '@sberdevices/plasma-icons';

//  LoadingOutlined

// import RecorderList from './rec/RecorderList.js'
 
// import { Modal } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { ThunderboltOutlined } from '@ant-design/icons';

// import { demo_meetings, demo_recognize } from './../../api/demodata.js'

// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
//     useHistory
//   } from "react-router-dom";

import './rec.css'
  

// import api from '../../api/api.js'
// import kilib from '../../dict/ki.js' 
// import { Content } from 'antd/lib/layout/layout';

// const coverImgUrl = 'https://5f96ec813d800900227e3b93-sdshhvdjxe.chromatic.com/images/320_320_0.jpg';
// const coverImgUrl = '/320_320_0.jpg';

import AboutKI from './AboutKI'

//
// TextRecFragment
//

export default class TextRecFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // list: this.props.reclist.list || []
            isAudioPlay: false,
        }
    }
    playFragment = (item) => {
        // console.log(this.props.reclist)
        if (this.props.reclist && this.props.reclist.isNowStageRecording) return 
        this.props.onPlay(item.id)
        let elem = document.getElementById('RL_'+item.id);
        this.setState({ isAudioPlay: false })
        elem.currentTime = 0;
        return elem.paused ? elem.play() : elem.pause();
    }
    stopFragment(id) {
        let elem = document.getElementById('RL_'+id);
        if (elem && !elem.paused) {
            elem.pause()
            this.setState({ isAudioPlay: false })
        }
    }

    onPlay(item) {
        this.setState({ isAudioPlay: true })
        // console.log('onPlay', item)
    }
    
    onEnded(item) {
        this.setState({ isAudioPlay: false })
        // console.log('onEnded', item)
    }

    renderItem(className, item) {
        if (!item.recognize || !item.recognize.text) return ''

        return (
            <span className={className} onClick={() => this.playFragment(item)}>

                    {/* &nbsp; */}
                    {/* {this.props.activeId} */}

                    { (item.recognize.KIs || []).map((x,i) => 
                        <AboutKI type={x.type} ki={x} key={i} withtitle />
                        // <AboutKI type={x} key={i} />
                    ) } 
                
                    <audio id={'RL_'+item.id} src={item.url} 
                        onPlay={() => {this.onPlay(item)}} 
                        onEnded={() => {this.onEnded(item)}} 
                        // controls 
                        />

                    <span>
                        {/* <b>v1</b>: &nbsp; */}
                        {item.recognize.text || ' '}
                        {/* &nbsp;&nbsp;| <b>v2</b>: &nbsp;&nbsp;
                        {item.recognize.text_v2 || '...'}
                        &nbsp; */}
                    </span>

                    {/* <mark> {item.status} </mark> &nbsp;| &nbsp; */}
                    {/* {item.statusHistory.map( (x,i) => (
                        <span key={i}>
                            <mark>{x}</mark>&nbsp;&nbsp;
                        </span>
                    ))}  */}

                    {/* <br/> */}
            </span>
        )
    } 
    renderSpiner(className, item) {
        if (item.status == 'IN_MIC') {
            return (
                <span className={className} style={{opacity: 0.5}}>
                    &nbsp;
                    <IconMicNone style={{display:'inline-block', width: '20px'}} />
                    &nbsp;
                    {/* <mark> {item.status} </mark> */}
                    {/* {item.statusHistory.map( (x,i) => (
                        <span key={i}>
                            <mark>{x}</mark>&nbsp;&nbsp;
                        </span>
                    ))} 
                    {/* <br/> */}
                </span>
            )
        } else {
            return (
                <span className={className}>
                    &nbsp;
                    <Spinner size={20} style={{display:'inline-block'}} />
                    &nbsp;
                    {/* <mark> {item.status} </mark> */}
                    {/* {item.statusHistory.map( (x,i) => (
                        <span key={i}>
                            <mark>{x}</mark>&nbsp;&nbsp;
                        </span>
                    ))} 
                    {/* <br/> */}
                </span>
            )
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.item.id != this.props.activeId) {
            this.stopFragment(this.props.item.id)
        }
    }

    render() {
        let item = this.props.item;
        let className = 'rl-fragment';

        // if (item.id != this.props.activeId) {
        //     this.stopFragment(item.id)
        // }

        if ( item.isCommand ) {
            className += ' rl-fragment-command';
        }
        if ( item.recognize ) {
            className += ' rl-fragment-recognized';
            if ( item.recognize.KIs.length !== 0 ) {
                className += ' rl-fragment-with-ki';
            }
            if ( this.state.isAudioPlay ) {
                className += ' rl-fragment-playing';
            }
            return this.renderItem(className, item)
        } else {
            className += ' rl-fragment-loading';
            return this.renderSpiner(className, item)
        }
    }
}
